import "./assets/css/style.css";
import NotFoundPage from "./components/molecules/not-found/NotFound";
import FooterSection from "./components/organisms/FooterSection";
import MintSection from "./components/organisms/MintSection";
import Home from "./components/pages/Home";
import "./web3/wallet-connect";
import { Routes, Route } from "react-router-dom";
import useNetworkSwitch from "./hooks/useSwitchNetwork";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  useNetworkSwitch();

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* <Route element={<RequireExceededPresaleDate />}> */}
        <Route path="/buy" element={<MintSection />} />
        {/* </Route> */}
      </Routes>
      <FooterSection />
    </>
  );
}

export default App;
