import React from "react";
import { Link } from "react-router-dom";
import ConnectButton from "../header/ConnectButton";

type Props = {};

const mintSectionHeader = (props: Props) => {
  return (
    <div className="mint-header">
      <Link to="/" className="go-back-home">
        <i className="fa-solid fa-arrow-left"></i> GO Back Home
      </Link>
      <div className="mobile_menu_btns">
        <ConnectButton />
      </div>
    </div>
  );
};

export default React.memo(mintSectionHeader);
