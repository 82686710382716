import React from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement, setValue } from '../../../redux/slices/quantitySlice';
import { RootState } from "../../../redux/store";

const schema = z.object({
  quantity: z
    .number()
    .min(0.01, { message: "Minimum is 0.01" })
    .max(0.5, { message: "Maximum is 0.5" }),
});

export const Counter = () => {
  const dispatch = useDispatch();
  const quantity = useSelector((state: RootState) => state.counter.value);

  const {
    control,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      quantity,
    },
  });

  const handleIncrement = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();    
    dispatch(increment());
  };

  const handleDecrement = (e:React.MouseEvent<HTMLButtonElement> ) => {
    e.preventDefault();
    dispatch(decrement());
  };

  return (
    <form>
      <div className="mint_quantity_sect">
        <button
          className="input-number-decrement"
          onClick={handleDecrement}
          type="button"
        >
          -
        </button>
        <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              className="input-number"
              type="number"
              step="0.01"
              min="0.01"
              max="0.5"
              value={quantity}
              readOnly={false}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                dispatch(setValue(newValue));
              }}
            />
          )}
        />
        <button
          className="input-number-increment"
          onClick={handleIncrement}
          type="button"
        >
          +
        </button>
      </div>
    </form>
  );
};
