import { JsonRpcProvider } from "ethers";
import { AutoPumpPresaleActions } from "../actions/AutoPumpPresaleActions";

interface StaticsData {
  raisedAmount: Number;
  fundraisingGoal: Number;
  rate: Number;
  token: string;
  presaleClosed: boolean;
}

export const fetchStaticsData = async (
  provider: JsonRpcProvider
): Promise<StaticsData> => {
  try {
    const raisedAmount = await AutoPumpPresaleActions.raisedAmount(provider);
    const fundraisingGoal = await AutoPumpPresaleActions.fundraisingGoal(
      provider
    );
    const rate = await AutoPumpPresaleActions.rate(provider);

    const token = await AutoPumpPresaleActions.token(provider);
    const presaleClosed = await AutoPumpPresaleActions.presaleClosed(provider);

    return {
      raisedAmount,
      fundraisingGoal,
      rate,
      token,
      presaleClosed,
    };
  } catch (error) {
    console.error("Error fetching statics data:", error);
    return {
      raisedAmount: 0,
      fundraisingGoal: 1,
      rate: 0,
      token: "",
      presaleClosed: false,
    };
  }
};
