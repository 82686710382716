import BannerSection from "../organisms/BannerSection";
import CarouselSection from "../organisms/CarouselSection";
import RoadmapSection from "../organisms/RoadmapSection";
import TeamSection from "../organisms/TeamSection";
import AboutUsSection from "../organisms/AboutUsSection";
import FeatureSection from "../organisms/FeaturesSection";
import Teckonomics from "../organisms/Tokenomics";
import FeesSection from "../organisms/FeesSection";
import Header from "../molecules/header/Header";
import TokenSection from "../organisms/TokenSection";
import BurnToken from "../organisms/BurnToken";

type Props = {};

const Home = (props: Props) => {
  return (
    <>
      <Header />
      <BannerSection />
      <CarouselSection />
      <TokenSection />
      <AboutUsSection />
      <Teckonomics />
      <FeesSection />
      <FeatureSection />
      <RoadmapSection />
      <TeamSection />
    </>
  );
};

export default Home;
