import HeaderLink from "../../atoms/header/HeaderLink";
import SocialIconLink from "../../atoms/ui/SocialIconLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import ConnectButton from "./ConnectButton";
import { HeaderItems } from "../../../constants/HeaderItems";
import React from "react";
import CloseButton from "../../atoms/header/CloseButton";

type Props = {
  ChangeIsMenueVisble: () => void;
};

const MobileHeaderMenu: React.FC<Props> = ({ ChangeIsMenueVisble }) => {
  return (
    <div className="autopump_mobile_menu_content">
      <div className="mobile_menu_logo">
        <CloseButton ChangeIsMenueVisble={ChangeIsMenueVisble} />
      </div>
      <div className="autopump_mobile_menu_list">
        <ul onClick={ChangeIsMenueVisble}>
          {HeaderItems.map((item) => (
            <HeaderLink
              name={item.name}
              href={item.href}
              className="mobile-menu-hide"
            />
          ))}
        </ul>
      </div>
      <div className="mobile_menu_btns">
        <ul className="mobile_menu_social_links">
          <li>
            <SocialIconLink
              url="https://twitter.com/autopumptoken"
              socialIcon={<FontAwesomeIcon icon={faXTwitter} />}
            />
          </li>
          <li>
            <SocialIconLink
              url="https://t.me/AutoPumpToken"
              socialIcon={<i className="fa-brands fa-telegram"></i>}
            />
          </li>
        </ul>
        <div
          className="connect-btn-wrapper w-100"
          onClick={ChangeIsMenueVisble}
        >
          <ConnectButton />
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderMenu;
