import Heading from "./Heading";

type ListItemProps = {
  title: string;
  content: React.ReactNode;
};

export const ListItem: React.FC<ListItemProps> = ({ title, content }) => (
  <>
    <li>
      <Heading>{title}</Heading>
      {content}
    </li>
  </>
);
