import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  presaleClosed: false,
};

const presaleSlice = createSlice({
  name: 'presale',
  initialState,
  reducers: {
    setPresaleClosed: (state, action) => {
      state.presaleClosed = action.payload;
    },
  },
});

export const { setPresaleClosed } = presaleSlice.actions;

export default presaleSlice.reducer;
