import React from "react";
import Image from "../../atoms/ui/Image";
import Paragraph from "../../atoms/ui/Paragraph";

interface FeatureCardProps {
  imgSrc: string;
  alt: string;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  imgSrc,
  alt,
  title,
  description,
}) => (
  <div className="col-md-4 mb-30" style={{marginBottom:"25px"}}>
    <div className="about_us_text_card">
      <h4 className="feature-card-heading">
        <Image src={imgSrc} alt={alt} />
        <span>{title}</span>
      </h4>
      <Paragraph text={description} />
    </div>
  </div>
);

export default FeatureCard;
