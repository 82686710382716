import React from "react";
import hov_shape_s from "../../../assets/images/icon/hov_shape_s.svg";
import "../../../assets/css/style.css";
import SmallImage from "./SmallImage";

type Props = {
  icon?: string;
  text: string;
  onClick: () => void;
  className: string
};

const Button: React.FC<Props> = ({ icon, text, onClick ,className}) => {
  return (
    // <div className="connect-btn-wrapper">
      <button
        className={className}
        data-bs-toggle="modal"
        data-bs-target="#connectModal"
        onClick={onClick}
      >
        {icon && <img src={icon} alt="" />}
        {text}
        <SmallImage className="hov_shape1" src={hov_shape_s}/>
        <SmallImage className="hov_shape2" src={hov_shape_s}/>
        <span className="square_hov_shape"></span>
      </button>
  );
};

export default Button;
