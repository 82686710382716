import SectionHeader from "../molecules/shared/SectionHeader";
import AboutUsTexts from "../molecules/about-us/AboutUsTexts";

const AboutUsSection = () => {
  return (
    <>
      <div className="v1_about_us_section" id="about">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-md-12">
              <div className="v1_about_us_right_sect">
                <SectionHeader title="The story" subtitle="About Us" />
                <div className="v1_about_us_right_text">
                  <AboutUsTexts />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsSection;
