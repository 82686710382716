 
type Props = {
  text:string
  url:string
  className:string
  imgSrc:string
};

const AnimatedButton = (props: Props) => {
  return (
    <a href={props.url} className={`btn-animate ${props.className}`} target="_blank" rel="noreferrer">
      <img src={props.imgSrc} alt="" />
      <strong>{props.text}</strong>
      <div id="container-stars">
        <div id="stars"></div>
      </div>

      <div id="glow">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </a>
  );
};

export default AnimatedButton;
