import { useEffect, useState } from "react";
import CustomWalletProvider from "../../../web3/custom-wallet-provider";
import { AutoPumpTokenActions } from "../../../actions/AutoPumpTokenActions";
import { formatCompactNumber } from "../../atoms/ui/FormatNumer";
import { truncateAddress } from "../../../utils/truncateAddress";

type Props = {};

const Burn = (props: Props) => {
  const [burnedTokens, setBurnedTokens] = useState(0);
  useEffect(() => {
    const getAutopumpThresholdPercentage = async () => {
      const alchemyProvider = CustomWalletProvider.getProvider();
      const res = await AutoPumpTokenActions.getNumberOfTokensBurn(
        alchemyProvider
      );

      setBurnedTokens(res);
    };
    getAutopumpThresholdPercentage();
  }, []);

  return (
    <>
      <div className="fire">
        <div className="fire-left">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-center">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-right">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-bottom">
          <div className="main-fire"></div>
        </div>
        <span className="token-burned-number">{formatCompactNumber(burnedTokens)}</span>
      </div>
    </>
  );
};

export default Burn;
