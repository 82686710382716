import React, { useState } from "react";
import immune_icon from "../../../assets/images/icon/IB audit Stamp 1.1.svg";
// import protect_icon from "../../../assets/images/icon/Protect.svg"

type Props = {};

const AuditToggleButton = (props: Props) => {
  // const [showDropdown, setShowDropdown] = useState(false);

  // const toggleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };

  return (
    <div className={`audit-by-container`}>
      <a
        href="https://certificate.immunebytes.com/pdf/autopump"
        rel="noreferrer"
        target="_blank"
      >
        <img src={immune_icon} alt="" />
      </a>
      {/* <div className={`dropdown-Auditby-menu ${showDropdown ? 'show' : ''}`}>
        <img src={immune_icon} alt="" />
        <a
          href="https://certificate.immunebytes.com/pdf/autopump"
          rel="noreferrer"
          target="_blank"

        >
          ImmuneBytes
        </a>
        <img src={protect_icon} alt="" />
      </div> */}
    </div>
  );
};

export default AuditToggleButton;
