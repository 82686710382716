import React from "react";
import Image from "../../atoms/ui/Image";
import SocialIconLink from "../../atoms/ui/SocialIconLink";

type SocialLink = {
  platform: "twitterX" | "instagram" | "linkedin";
  url: string;
};

type Props = {
  src: string;
  alt: string;
  name: string;
  role: string;
};

const TeamMemberCard: React.FC<Props> = ({ src, alt, name, role }) => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="autopump_team_card hov_shape_team">
          <div className="team_member_img">
            <Image src={src} alt={alt} />
          </div>
          <h3>{name}</h3>
          <h4>{role}</h4>
        </div>
      </div>
    </>
  );
};

export default TeamMemberCard;
