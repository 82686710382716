import React from "react";

type Props = {
  title: string;
  percentage: number;
};

const FeesProgressCircle: React.FC<Props> = ({ title, percentage }) => {
  return (
    <div className="card">
      <div className="box">
        <div className="percent">
          <div className={`number ${title}`}>
            <h2>
              {percentage}
              <span>%</span>
            </h2>
          </div>
        </div>
        <h2 className={`text ${title}`}>{title}</h2>
      </div>
    </div>
  );
};

export default FeesProgressCircle;
