import Button from "../../atoms/ui/Button";
import wallet from "../../../assets/images/icon/connect_wallet.svg";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import DisconnectButton from "./DisconnectButton";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../redux/slices/loaderSlice";

type Props = {};

const ConnectButton = (props: Props) => {
  const { open } = useWeb3Modal();

  const { address, isConnected } = useWeb3ModalAccount();


  const onConnect = () => {
    open();
  };

  return (
    <div className="connect-btn-wrapper w-100">
      <div className="flex">
        {!isConnected ? (
          <Button
            text="connect"
            icon={wallet}
            onClick={() => {
              onConnect();
            }}
            className="connect_btn hov_shape_show"
          />
        ) : (
          <DisconnectButton icon={wallet} walletAddress={address ?? ""} />
        )}
      </div>
    </div>
  );
};

export default ConnectButton;
