import React from "react";

type Props = {
  url: string;
  socialIcon: React.ReactNode;
};

const SocialIconLink: React.FC<Props> = ({ url, socialIcon }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {socialIcon}
    </a>
  );
};

export default SocialIconLink;
