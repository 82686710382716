import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lockupPeriod: '',
};

const lockupPeriodSlice = createSlice({
  name: "lockupPeriod",
  initialState,
  reducers: {
    setlockupPeriod: (state, action) => {
      state.lockupPeriod = action.payload;
    },
  },
});

export const { setlockupPeriod } = lockupPeriodSlice.actions;

export default lockupPeriodSlice.reducer;
