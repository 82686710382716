import React from "react";
import ButtonLink from "../../atoms/ui/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane, faTwitter, faXTwitter } from "@fortawesome/free-brands-svg-icons";

const ButtonLinks: React.FC = () => {
  return (
    <>
      <ButtonLink
        buttonText="Telegram"
        buttonClass="telegram_btn"
        icon={<FontAwesomeIcon icon={faTelegramPlane} />}
        url="https://t.me/AutoPumpToken"
      />
      <ButtonLink
        buttonText="X (twitter)"
        buttonClass="twitterX_btn"
        icon={ <FontAwesomeIcon icon={faXTwitter} />}
        url="https://x.com/autopumptoken"
      />
    </>
  );
};

export default ButtonLinks;
