import React from "react";

type Props = {
  isMenuVisible: boolean;
  ChangeIsMenueVisble: () => void;
};

const BurgerBars: React.FC<Props> = ({
  isMenuVisible,
  ChangeIsMenueVisble,
}) => {
  return (
    <button
      className="menu_bar"
      style={{ display: !isMenuVisible ? "block" : "none" }}
      onClick={ChangeIsMenueVisble}
    >
      <i className="fa-solid fa-bars"></i>
    </button>
  );
};

export default BurgerBars;
