import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

// 1. Get projectId
const projectId = process.env.REACT_APP_API_WALLET_CONNECT_PROJECT_ID ?? "";

// 2. Set chains
// Base Mainnet Configuration
const baseMainnet = {
  chainId: 8453,
  name: "Base Mainnet",
  currency: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: "https://mainnet.base.org",
  //   rpcUrl: "https://developer-access-mainnet.base.org",
};

const baseSepoliaTestNet = {
  chainId: 84532,
  name: "Base Sepolia",
  currency: "ETH",
  explorerUrl: "https://sepolia-explorer.base.org/",
  rpcUrl: "https://sepolia.base.org",
  //   rpcUrl: "https://developer-access-mainnet.base.org",
};

const mumbaiTestNet = {
  chainId: 80001,
  name: "Mumbai Testnet",
  currency: "MATIC",
  explorerUrl: " https://polygonscan.com/",
  rpcUrl: "https://polygon-mumbai-bor-rpc.publicnode.com",
};

// 3. Create a metadata object
const metadata = {
  name: "Auto Pump",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
export const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [baseMainnet, baseSepoliaTestNet, mumbaiTestNet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});