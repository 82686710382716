import SectionHeader from "../molecules/shared/SectionHeader";
import FeesProgressCircle from "../molecules/fees/FeesProgressCircle";

const FeesSection = () => {
  const skills = [
    { id: 1, title: "AutoPump", percentage: 1 },
    { id: 2, title: "Burn", percentage: 1 },
    { id: 3, title: "Liquidity", percentage: 1 },
  ];

  return (
    <div className="fees" id="fees">
      <SectionHeader title="Fees" subtitle="Transaction Fees" />
      <div className="okvir">
        {skills.map((skill) => (
          <FeesProgressCircle
            key={skill.id}
            title={skill.title}
            percentage={skill.percentage}
          />
        ))}
      </div>
    </div>
  );
};

export default FeesSection;
