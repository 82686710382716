import autopump from "../assets/images/icon/autopump.svg";
import arrows from "../assets/images/icon/arrows.svg";
import infinity from "../assets/images/icon/infinity-icon.svg";
import ingots from "../assets/images/icon/ingots.svg";
import arbitrage from "../assets/images/icon/arbitrage.svg";
import burn from "../assets/images/icon/burn.svg";

export const featureData = [
  {
    id: 1,
    imgSrc: autopump,
    alt: "NFT Breeding Icon",
    title: "Pump Your Bag",
    description:
      "AutoPump ups your bag's worth with each swap. Just HODL and enjoy the ride.",
  },
  {
    id: 2,
    imgSrc: arrows,
    alt: "Staking Icon",
    title: "DEX Arbitrage Win",
    description:
      "On two DEXes, AutoPump makes finding profit easy. Buy low here, sell high there.",
  },
  {
    id: 3,
    imgSrc: infinity,
    alt: "Reward Ecosystem Icon",
    title: "Grow Your Bag",
    description:
      "Our smart buy-and-burn game keeps your bag pumping up without the dump.",
  },
  {
    id: 4,
    imgSrc: ingots,
    alt: "Gamming NFTs Icon",
    title: "HODL or Trade",
    description:
      "Keep tokens for growth or trade for quick cash. AutoPump works both ways.",
  },
  {
    id: 5,
    imgSrc: arbitrage,
    alt: "Play to Earn Icon",
    title: "Grab the Gap",
    description:
      "With AutoPump, buy cheap on one DEX, sell high on another. Easy profit.",
  },
  {
    id: 6,
    imgSrc: burn,
    alt: "Metaverse Ready Icon",
    title: "Scarcity Value Spike",
    description:
      "Less is more: AutoPump burns for a tighter bag. Small supply, big demand.",
  },
];
