import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../redux/slices/ModalSlice';
import Modal from '../molecules/Modal/Modal';
import { RootState } from '../../redux/store';

const ConnectedModal = () => {
  const { isOpen, message, status } = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  // Determine heading text and className based on the status
  const headingText = status === 'success' ? 'Success' : 'Failed';
  const headingClass = status === 'success' ? 'green' : 'red';

  return (
    <Modal setModalIsOpen={() => dispatch(closeModal())}>
      <h2 className={headingClass}>{headingText}</h2>
      <p>{message}</p>
    </Modal>
  );
};

export default ConnectedModal;
