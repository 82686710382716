import React from "react";
import Lottie from "react-lottie";

type Props = {
  animationData: object;
};

const AnimatedImage: React.FC<Props> = ({ animationData }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height="100%" width="100%" />;
};

export default AnimatedImage;
