import React from "react";
import mint_live_icon from "../../../assets/images/icon/mint_live_icon.svg";
import SmallImage from "../ui/SmallImage";

interface IconTextProps {
  text: string;
}

const CarouselIconText: React.FC<IconTextProps> = ({ text }) => {
  return (
    <li>
      <SmallImage src={mint_live_icon} />
      {text}
    </li>
  );
};

export default CarouselIconText;
