import v4_star2 from "../../../assets/images/icon/v4_star2.svg";
import v4_star3 from "../../../assets/images/icon/v4_star3.svg";
import animationData from "../../../lottie/Rocketman F2.json";
import AnimatedImage from "../../atoms/animated-image/AnimatedImage";


type Props = {};

const RightBanner = (props: Props) => {
  return (
    <div className="autopump_v1_baner_right autopump_v4_baner_right">
      <span className="star2">
        <img src={v4_star2} alt="star" />
      </span>
      <span className="star3">
        <img src={v4_star3} alt="star" />
      </span>
      <div className="lottie-banner-image">
        <AnimatedImage animationData={animationData} />
      </div>
    </div>
  );
};

export default RightBanner;
