import React from "react";
import Button from "./Button";

interface ButtonLinkProps {
  buttonText: string;
  buttonClass: string;
  url?: string;
  icon?: React.ReactNode;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  buttonText,
  buttonClass,
  icon,
  url = "#",
}) => (
  <a href={url} target="_blank" rel="noreferrer">
    <Button
      text={buttonText}
      onClick={()=>{}}
      // icon={icon}
      className={`${buttonClass} hov_shape_show uppercase`}
    />
  </a>
);

export default ButtonLink;
