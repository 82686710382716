import React from "react";
import CarouselIconText from "../../atoms/carousel/CarouselIconText";

interface CarouselIconTextListProps {
  items: { text: string }[];
}

const CarouselIconTextList: React.FC<CarouselIconTextListProps> = ({
  items,
}) => (
  <ul>
    {items.map((item, index) => (
      <CarouselIconText key={index} text={item.text} />
    ))}
  </ul>
);

export default CarouselIconTextList;
