import RoadmapCard from "../molecules/road-map/RoadmapCard";
import SectionHeader from "../molecules/shared/SectionHeader";
import roadmap_v2_middle_shape from "../../assets/images/nft/roadmap_v2_middle_shape.png";
import { phases } from "../../constants/Roadmap";
import AnimatedImage from "../atoms/animated-image/AnimatedImage";
import animationData from "../../lottie/dev1.json";

const RoadmapSection = () => {
  return (
    <div className="v2_roadmap_sect" id="roadmap">
      <div className="container">
        <div
          className="lottie-image"
          style={{ width: "400px", height: "400px" }}
        >
          <AnimatedImage animationData={animationData} />
        </div>
        <div className="autopump_title_section text-center">
          <SectionHeader title="Our Goals" subtitle="Roadmap" />
        </div>
        <div className="v2_rodmap_card_sect">
          <div className="v2_roadmap_divider">
            <img src={roadmap_v2_middle_shape} alt="" />
          </div>
          <div className="row">
            {phases.map((phase, index) => (
              <RoadmapCard
                key={index}
                phase={phase.phase}
                items={phase.items}
                isCompleted={phase.isCompleted}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapSection;
