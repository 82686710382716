import autoPumpTokenABI from "./abi/auto-pump-token.json";
import autoPumpPresaleABI from "./abi/auto-pump-presale.json";

export const ABIS = { autoPumpTokenABI, autoPumpPresaleABI };

export const CHAIN_IDS = {
  baseMainnet: "0x2105", // 8453,
  mumbaiTestNet: "0x13881", // 80001
};
export const CONTRACTS = {
  autoPumpPresale:
    process.env.REACT_APP_API_ENV === "dev"
      ? process.env.REACT_APP_API_AUTO_PUMP_PRESALE_TEST_NETWORK ?? ""
      : process.env.REACT_APP_API_AUTO_PUMP_PRESALE_MAIN_NETWORK ?? "",
};
