import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StaticsState {
  raisedAmount: Number;
  fundraisingGoal: Number;
  price: Number;
}

const initialState: StaticsState = {
  raisedAmount: 0,
  fundraisingGoal: 0,
  price: 0,
};

const staticsSlice = createSlice({
  name: 'statics',
  initialState,
  reducers: {
    setRaisedAmount: (state, action: PayloadAction<Number>) => {
      state.raisedAmount = action.payload;
    },
    setFundraisingGoal: (state, action: PayloadAction<Number>) => {
      state.fundraisingGoal = action.payload;
    },
    setPrice: (state, action: PayloadAction<Number>) => {
      state.price = action.payload;
    },
  },
});

export const { setRaisedAmount, setFundraisingGoal, setPrice } = staticsSlice.actions;

export default staticsSlice.reducer;
