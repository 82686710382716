import React from "react";
import { ListItem } from "../../atoms/mint/ListItem";
import Heading from "../../atoms/mint/Heading";
import { formatCompactNumber } from "../../atoms/ui/FormatNumer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type Props = {};

const Statics = (props: Props) => {
  const { raisedAmount, fundraisingGoal, price } = useSelector((state: RootState) => state.statics);

  return (
    <ul className="statics">
      <ListItem
        title="Raised Amount"
        content={
          <Heading>
            <>{raisedAmount} ETH</>
          </Heading>
        }
      />

      <ListItem
        title="Fund Raising Goal"
        content={
          <Heading>
            <>{fundraisingGoal} ETH</>
          </Heading>
        }
      />

      <ListItem
        title="Remaining"
        content={
          <Heading>
            <span>{Number(fundraisingGoal) - Number(raisedAmount)} ETH</span>
          </Heading>
        }
      />

      <ListItem
        title="Price"
        content={
          <Heading>
            <span className="green uppercase">1 ETH =</span> &nbsp;
            <>{formatCompactNumber(+price)} AUTO</>
          </Heading>
        }
      />
    </ul>
  );
};

export default React.memo(Statics);
