import title_shapes2 from "../../../assets/images/icon/title_shapes2.svg";
import title_shapes from "../../../assets/images/icon/title_shapes.svg";
import Image from "../../atoms/ui/Image";
import React from "react";

type Props = {
  title: string;
  subtitle: string;
};

const SectionHeader: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="autopump_title_section text-center">
      <h2>
        <span className="shape_left">
          <Image src={title_shapes2} alt="" />
        </span>
        {title}
        <span>
          <Image src={title_shapes} alt="" />
        </span>
      </h2>
      <h3>{subtitle}</h3>
    </div>
  );
};

export default SectionHeader;
