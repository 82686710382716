import React from "react";

type Props = {
  src: string;
  className?: string;
  alt?: string;
};

const SmallImage: React.FC<Props> = ({ src, className = "", alt = "" }) => {
  return (
    <span className={className}>
      <img src={src} alt={alt} />
    </span>
  );
};

export default SmallImage;
