export const phases = [
  {
    id: 1,
    phase: "01",
    items: [
      {
        title: "Smart Contract Deployment",
      },
      {
        title: "Global Community Hubs",
      },
      {
        title: "Pre-Sale Strategy",
      },
      {
        title: "Detailed Protocol Blueprint",
      },
    ],
    isCompleted: true,
  },
  {
    id: 2,
    phase: "02",
    items: [
      {
        title: "Community Airdrop Initiatives",
      },
      {
        title: "Cross-Chain Asset Transfers",
      },
      {
        title: "Targeted DEX Listings",
      },
      {
        title: "Visibility on Analytics Platforms",
      },
    ],
    isCompleted: false,
  },
  {
    id: 3,
    phase: "03",
    items: [
      {
        title: "AI-Driven Meme Index",
      },
      {
        title: "Strategic Fintech Partnerships",
      },
      {
        title: "Targeted Marketing Campaigns",
      },
    ],
    isCompleted: false,
  },
  {
    id: 4,
    phase: "04",
    items: [
      {
        title: "Governance via DAO Structure",
      },
      {
        title: "Refined Fee System",
      },
      {
        title: "Centralized Exchange Outreach",
      },
      {
        title: "Revolutionary Feature Launch",
      },
    ],
    isCompleted: false,
  },
];
