import React from "react";
import { useDisconnect } from "@web3modal/ethers/react";
import Button from "../../atoms/ui/Button";
import wallet from "../../../assets/images/icon/connect_wallet.svg";
import { truncateText } from "../../../utils/trucateText";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../redux/slices/loaderSlice";

type Props = {
  icon?: string;
  walletAddress: string;
};

const DisconnectButton: React.FC<Props> = ({ icon, walletAddress }) => {
  const { disconnect } = useDisconnect();
  const address = truncateText(walletAddress);
  const dispatch = useDispatch();

  return (
    <div className="connect-btn-wrapper">
      <Button
        className="connect_btn hov_shape_show"
        text={address}
        onClick={() => {
          disconnect();
          dispatch(setIsLoading(true));
        }}
        icon={wallet}
      />
    </div>
  );
};

export default DisconnectButton;
