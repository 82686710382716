import { ethers } from "ethers";
import { AutoPumpPresaleActions } from "../actions/AutoPumpPresaleActions";

export interface InvestorInfo {
  tokenBalance: Number;
  TotalTokensWithdrawn: Number;
  balance: Number;
  eligibleTokens:Number
}

export const fetchInvestorInfo = async (
  provider: ethers.Provider,
  address: `0x${string}` | undefined
): Promise<InvestorInfo> => {
  try {
    let tokenBalance: Number = 0;
    let TotalTokensWithdrawn: Number = 0;
    let balance: Number = 0;
    let eligibleTokens: Number = 0

    if (address != null) {
      tokenBalance = await AutoPumpPresaleActions.getTokenBalance(
        provider,
        address
      );

      TotalTokensWithdrawn =
        await AutoPumpPresaleActions.getTotalTokensWithdrawn(provider, address);

      balance = await AutoPumpPresaleActions.getTokenBalance(provider, address);
      eligibleTokens = await AutoPumpPresaleActions.calculateEligibleTokens(provider, address);
      
    }

    return { tokenBalance, TotalTokensWithdrawn, balance,eligibleTokens };
  } catch (error) {
    console.error("Error fetching investor info:", error);
    return {
      tokenBalance: 0,
      TotalTokensWithdrawn: 0,
      balance: 0,
      eligibleTokens:0
    };
  }
};
