import { ethers } from "ethers";

class WalletProvider {
  private static publicProvider: ethers.JsonRpcProvider;

  static getProvider = (): ethers.JsonRpcProvider => {
    if (WalletProvider.publicProvider == null) {
      try {
        if (process.env.REACT_APP_API_ENV === "dev") {
          WalletProvider.publicProvider = new ethers.JsonRpcProvider(
            process.env.REACT_APP_API_ALCHEMY_MUMBAI_PROVIDER
          );
        } else {
          WalletProvider.publicProvider = new ethers.JsonRpcProvider(
            process.env.REACT_APP_API_ALCHEMY_BASE_PROVIDER
          );
        }
      } catch (error) {
        // HERE: Error message
      }
    }

    return WalletProvider.publicProvider;
  };
}

export default WalletProvider;
