import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./Modal.css";
interface ModalProps {
  children: ReactNode;
  setModalIsOpen: (isOpen: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ children, setModalIsOpen }) => {  
  const handleClose = () => {
    setModalIsOpen(false);
  };
  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={handleModalContentClick}>
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")!
  );
};

export default Modal;
