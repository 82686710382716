import React from "react";

type Props = {
  symbol: string;
};

const Pricesymbol: React.FC<Props> = ({ symbol }) => {
  return (
    <span style={{ color: "#00ffa3", paddingInline: "2px" }}>{symbol}</span>
  );
};

export default Pricesymbol;
