import React from "react";
import SectionHeader from "../molecules/shared/SectionHeader";
import AmChartComponent from "../molecules/Tokenomics/AmChartComponent";
import { tokenomics } from "../../constants/tokenomics";

type Props = {};

const Tokenomics = (props: Props) => {
  return (
    <div
      className="contianer tokenomics"
      id="tokenomics"
      style={{ paddingBlock: "80px" }}
    >
      <SectionHeader title="The Coin" subtitle="Tokenomics" />
      <AmChartComponent data={tokenomics} />
    </div>
  );
};

export default Tokenomics;
