import React from "react";

type Props = {
  name: string;
  href: string;
  className?: string;
};

const HeaderLink: React.FC<Props> = ({ name, href, className = "" }) => {
  return (
    <li key={name} className={className}>
      <a href={href}>{name}</a>
    </li>
  );
};

export default HeaderLink;
