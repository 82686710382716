import React from "react";
import { truncateText } from "../../../utils/trucateText";

type Props = {
  text: string;
};

const TruncateText: React.FC<Props> = ({ text }) => {
  return <div>{truncateText(text)}</div>;
};

export default TruncateText;
