type SocialLinks = {
  href: string;
  iconName: "faXTwitter" | "faGithub" | "faTelegramPlane";
  className: string;
};

export const socialLinks: SocialLinks[] = [
  {
    href: "https://twitter.com/autopumptoken",
    iconName: "faXTwitter",
    className: "twitter-link-icon",
  },
  {
    href: "https://github.com/autopump",
    iconName: "faGithub",
    className: "github-link-icon",
  },
  {
    href: "https://t.me/AutoPumpToken",
    iconName: "faTelegramPlane",
    className: "telegram-link-icon",
  },
];
