import { JsonRpcProvider } from "ethers";
import { AutoPumpPresaleActions } from "../actions/AutoPumpPresaleActions";

export const fetchProgressBarData = async (provider: JsonRpcProvider) => {
  try {
    const currentFundRasingGoal = await AutoPumpPresaleActions.fundraisingGoal(
      provider
    );
    const currentRaisedAmount = await AutoPumpPresaleActions.raisedAmount(
      provider
    );

    const percentage =
      (+currentRaisedAmount /
        (+currentFundRasingGoal === 0 ? 1 : +currentFundRasingGoal)) *
      100;

    return { percentage };
  } catch (error) {
    console.error("Error fetching progress bar data:", error);
    return { percentage : 0}
  }
};
