import { useEffect } from "react";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { CHAIN_IDS } from "../config/web3";

// import { Eip1193Provider } from "ethers/types/providers";

// declare global {
//   interface Window {
//     ethereum: Eip1193Provider & BrowserProvider;
//   }
// }

// interface Window {
//   ethereum: any;
// }

// declare global {
//   interface Window {
//     ethereum?: any;
//   }
// }

const useNetworkSwitch = () => {
  let ethereum: any = window.ethereum;

  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    const switchNetwork = async () => {
      try {
        if (!isConnected || walletProvider == null) {
          return;
        }

        const provider = new BrowserProvider(walletProvider);

        const targetNetwork =
          process.env.REACT_APP_API_ENV === "dev"
            ? CHAIN_IDS.mumbaiTestNet
            : CHAIN_IDS.baseMainnet;

        // Request network switch
        try {
          // Try switching to the target network
          //   await provider.send("wallet_switchEthereumChain", [
          //     { chainId: targetNetwork },
          //   ]);
          if (ethereum) {
            // await ethereum?.request({
            //   method: "wallet_switchEthereumChain",
            //   params: [{ chainId: targetNetwork }],
            // });
            await ethereum?.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  //   chainId: "0x13881",
                  chainId: targetNetwork,
                },
              ],
            });
          }
        } catch (switchError: any) {
          if (switchError.code === 4902) {
            try {
              // The requested network is not added to the user's wallet, so try to add it
              await provider.send("wallet_addEthereumChain", [targetNetwork]);
            } catch (addError) {
              console.error("Network add error:", addError);
            }
          } else {
            console.error("Network switch error:", switchError);
          }
        }
      } catch (switchError) {
        console.error("Network switch error:", switchError);
        // Handle errors, such as user rejecting the request
      }
    };

    switchNetwork();
  }, [chainId, walletProvider]);
};

export default useNetworkSwitch;