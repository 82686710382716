import { aboutUsContent } from "../../../constants/aboutUsContent";
import Paragraph from "../../atoms/ui/Paragraph";

const AboutUsTexts = () => {
  return (
    <>
      {aboutUsContent.map((text) => (
        <Paragraph text={text} key={text}/>
      ))}
    </>
  );
};

export default AboutUsTexts;
