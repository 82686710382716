import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProgressBarState {
  percentage: number;
}

const initialState: ProgressBarState = {
  percentage: 0,
};

const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    setPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
  },
});

export const { setPercentage } = progressBarSlice.actions;

export default progressBarSlice.reducer;
