import React from "react";
import { HeaderItems } from "../../../constants/HeaderItems";
import HeaderLink from "../../atoms/header/HeaderLink";
import ConnectButton from "./ConnectButton";
import BurgerBars from "../../atoms/header/BurgerBars";
import AuditToggleButton from "./AuditToggleButton";

type Props = {
  isMenuVisible: boolean;
  ChangeIsMenueVisble: () => void;
};

const HeaderRightPart: React.FC<Props> = ({
  isMenuVisible,
  ChangeIsMenueVisble,
}) => {
  return (
    <div className="autopump_menu_right_sect autopump_v1_menu_right_sect">
      <div className="autopump_menu_list">
        <ul>
          {HeaderItems.map((item) => (
            <HeaderLink name={item.name} href={item.href} />
          ))}
        </ul>
      </div>
      <div className="autopump_menu_right_buttons">
        <AuditToggleButton/>
        <ConnectButton />
        <BurgerBars
          isMenuVisible={isMenuVisible}
          ChangeIsMenueVisble={ChangeIsMenueVisble}
        />
      </div>
    </div>
  );
};

export default HeaderRightPart;
