import React, { useRef, useEffect, useState } from "react";
// Importing amCharts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

type ChartDataItem = {
  name: string;
  percentage: number;
  color1: string;
  color2: string;
};

type AmChartComponentProps = {
  data: ChartDataItem[];
};

// Apply theme
am4core.useTheme(am4themes_animated);

const AmChartComponent: React.FC<AmChartComponentProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();
    if (chartRef.current) {
      // Since createFromConfig does not have a specific type for its return value,
      // you might not be able to directly type the chart as am4charts.PieChart3D without an assertion.
      const chart: am4charts.PieChart3D = am4core.createFromConfig(
        {
          hiddenState: { opacity: 0 },
          angle: 45,
          innerRadius: am4core.percent(40),
          outerRadius: am4core.percent(100),
        },
        chartRef.current,
        am4charts.PieChart3D,
      ) as am4charts.PieChart3D;

      chart.logo.disabled = true;

      const series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "percentage";
      series.dataFields.category = "name";
      series.slices.template.strokeWidth = 0;
      series.labels.template.disabled = true;
      series.ticks.template.disabled = true;
      series.slices.template.tooltipText = "";
      chart.legend = new am4charts.Legend();
      chart.legend.labels.template.fill = am4core.color("#FFFFFF");
      chart.legend.valueLabels.template.fill = am4core.color("#FFFFFF");

      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.togglable = false;
      chart.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      chart.legend.itemContainers.template.events.on("hit", (event) => {
        // event.stopPropagation();
      });

      chart.data = data;
      series.slices.template.propertyFields.fill = "color";
      chart.events.on("datavalidated", function () {
        chart.data.forEach(function (item, index) {
          var gradient = new am4core.LinearGradient();
          gradient.addColor(am4core.color(item.color1));
          gradient.addColor(am4core.color(item.color2));

          var slice = series.slices.getIndex(index);
          if (slice) {
            slice.fill = gradient;
          }
        });
      });

      if (windowWidth < 500) {
      }

      if (series.slices.template.states.getKey("hover")) {
        series.slices.template.states.getKey(
          "hover",
        )!.properties.shiftRadius = 0.1;
        series.slices.template.states.getKey("hover")!.properties.scale = 1.1;
      }

      const max = chart.data.length - 1;
      let activeIdx = 0;
      const disableSlice = () => {
        const prevIdx = activeIdx === 0 ? max : activeIdx - 1;
        const nextIdx = activeIdx === max ? 0 : activeIdx + 1;

        const prevSlice = series.slices.getIndex(prevIdx);
        if (prevSlice) {
          prevSlice.isActive = false;
        }

        const activeSlice = series.slices.getIndex(activeIdx);
        if (activeSlice) {
          activeSlice.isActive = true;
        }

        activeIdx = nextIdx;
      };

      const intervalId = setInterval(() => {
        disableSlice();
      }, 1000);

      return () => {
        clearInterval(intervalId);
        window.removeEventListener("resize", handleResize);
        chart.dispose();
      };
    }
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default AmChartComponent;
