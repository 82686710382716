import RightBanner from "../molecules/main-banner/RightBanner";
import LeftBanner from "../molecules/main-banner/LeftBanner";

type Props = {};

const BannerSection = (props: Props) => {
  return (
    <div className="autopump_v4_baner_sect" id="home">
      <canvas id="canvas" className="v4firefly_bg"></canvas>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-6">
            <RightBanner />
          </div>
          <div className="col-lg-6 z-index align-text-center">
            <LeftBanner />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
