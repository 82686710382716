// CarouselSection.tsx
import React from "react";
import CarouselIconTextList from "../molecules/carousel/CarouselIconTextList";

const carouselItems = [{ text: "Listing on Uniswap and Sushiswap has been officially confirmed !" }];

const CarouselSection: React.FC = () => (
  <div className="autopump_v4owl_carousel_sect">
    <div className="autopump_v4owl_carousel_content">
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
      <CarouselIconTextList items={carouselItems} />
    </div>
  </div>
);

export default CarouselSection;
