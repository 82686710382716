// In src/redux/slices/quantitySlice.js
import { createSlice } from '@reduxjs/toolkit';

export const quantitySlice = createSlice({
  name: 'quantity',
  initialState: {
    value: 0.01,
  },
  reducers: {
    increment: (state) => {
      state.value = state.value + 0.01 <= 0.5 ? Number((state.value + 0.01).toFixed(2)) : state.value;
    },
    decrement: (state) => {
      state.value = state.value - 0.01 >= 0.01 ? Math.max(Number((state.value - 0.01).toFixed(2)), 0.01) : state.value;
    },
    setValue: (state, action) => {
        state.value = action.payload;
      },
  },
});

// Export actions
export const { increment, decrement ,setValue} = quantitySlice.actions;

// Export reducer
export default quantitySlice.reducer;
