import { useNavigate } from "react-router-dom";
import Button from "../atoms/ui/Button";
import SwapingButtons from "../molecules/main-banner/SwapingButtons";
import DateCountDown from "../molecules/shared/DateCountDown";

const MainBannerMintCounter = () => {
  const navigate = useNavigate();

  const countdownDate = process.env.REACT_APP_API_PRESALE_DATE || "";

  const currentTime = new Date().getTime();
  const eventTime = new Date(countdownDate).getTime();
  const isEventTimePassed = currentTime > eventTime;
  return (
    <div
      className="container count-down-container"
      style={{ paddingLeft: "0px" }}
    >
      {!isEventTimePassed && (
        <div className="col-lg-12">
          <div className="banner-conent3">
            <div className="autopump_v3_timer">
              <DateCountDown date={countdownDate} />
            </div>
          </div>
        </div>
      )}
      {isEventTimePassed && (
        <div className="autopump_v3_baner_buttons red">
          <SwapingButtons />
          <Button
            onClick={() => navigate("/buy")}
            className="mint_btn green hov_shape_show"
            text="Claim"
          />
        </div>
      )}
      <br />
    </div>
  );
};

export default MainBannerMintCounter;
