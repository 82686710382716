import _chr_img from "../../assets/images/nft/3_chr_img.png";

import FooterButtons from "../molecules/footer/FooterButtons";
import FooterSocialIcon from "../atoms/footer/FooterSocialIcon";
import { socialLinks } from "../../constants/socialLinks";
import CopyRight from "../molecules/footer/CopyRight";
import AnimatedStars from "../molecules/footer/AnimatedStars";

type Props = {};

const FooterSection = (props: Props) => {
  return (
    <div className="autopump_v1_footer_sect" id="community">
      <div className="container">
        <div className="autopump_title_section text-center">
          <h3>
            Join Our community & <br />
            get Early access <br />
            🤙
          </h3>
        </div>
        <div className="autopump_v1_footer_content">
          <div className="join_comunity_btns">
            <FooterButtons />
          </div>
          <div className="footer_social_links">
            <ul>
              {socialLinks.map((link, index) => (
                <FooterSocialIcon
                  key={index}
                  href={link.href}
                  iconName={link.iconName}
                  className={link.className || ""}
                />
              ))}
            </ul>
          </div>
          <AnimatedStars />
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default FooterSection;
