import PresaleStatusIndicator from "../atoms/PresaleStatusIndicator";
import ProgressBar from "../molecules/progress-bar/ProgressBar";

import CustomWalletProvider from "../../web3/custom-wallet-provider";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AutoPumpPresaleActions } from "../../actions/AutoPumpPresaleActions";
import utc from "dayjs/plugin/utc";
import Statics from "../molecules/statics/Statics";
import DateCountDown from "../molecules/shared/DateCountDown";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

dayjs.extend(utc);

const WithDrawalCounter = () => {
  const percentage = useSelector((state: RootState) => state.progressBar.percentage);
  const [withDrawalPeriod, setwithDrawalPeriod] = useState<string | undefined>(
    undefined
  );
  const [presaleClosed, setPresaleClosed] = useState<boolean>(false);


  const now = dayjs.utc();
  const withDrawalEndDate = dayjs.utc(withDrawalPeriod);

  useEffect(() => {
    const initialize = async () => {
      const alchemyProvider = CustomWalletProvider.getProvider();

      const presaleCloseStatus = await AutoPumpPresaleActions.presaleClosed(
        alchemyProvider
      );
      setPresaleClosed(presaleCloseStatus);

      if (!presaleCloseStatus) {
        return;
      }

      const withDrawalPeriodDays =
        await AutoPumpPresaleActions.WITHDRAWAL_PERIOD_DAYS(alchemyProvider);

      const lockupPeriodDays = await AutoPumpPresaleActions.LOCKUP_PERIOD_DAYS(
        alchemyProvider
      );

      const closedPresaleTime = await AutoPumpPresaleActions.closedPresaleTime(
        alchemyProvider
      );

      // Ensure all values are treated as numbers. Convert days to seconds and then to milliseconds at the end.
      const totalPeriodSeconds =
        Number(closedPresaleTime) + Number(withDrawalPeriodDays) + Number(lockupPeriodDays);

      // Convert seconds to milliseconds
      const totalPeriodMillis = totalPeriodSeconds * 1000;

      const date = dayjs.utc(totalPeriodMillis);
      const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss[Z]");

      setwithDrawalPeriod(formattedDate);
    };
    initialize();
  }, [withDrawalPeriod]);

  return (
    <div
      className="container"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="col-lg-12">
        <div className="banner-conent3">
          <PresaleStatusIndicator soldOut={false} />

          <div className="autopump_v3_timer">
            {presaleClosed && withDrawalEndDate > now ? (
              <h5 className="uppercase">withDrawal ends in</h5>
            ) : (
              <h5 className="uppercase">Claim Your Tokens</h5>
            )}
            {presaleClosed && (
              <DateCountDown date={withDrawalPeriod}/>
            )}
          </div>

          <ProgressBar percentage={percentage} header="presale goal"/>

          {!presaleClosed && (
            <div className="banner-bottom-text uppercase">
              Minimum buy is 0.01 <span className="green">ETH</span>
              <br />
              Maximum buy is 0.5 <span className="green">ETH</span>
            </div>
          )}
          <Statics />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default WithDrawalCounter;
