export const tokenomics = [
  {
    name: "Presale",
    percentage: 40,
    color1: "#0d6efd",
    color2: "#7539f9",
  },
  {
    name: "Liquidity",
    percentage: 40,
    color1: "#23906e",
    color2: "#10e7a4",
  },
  {
    name: "Airdrop",
    percentage: 10,
    color1: "#f9bc30",
    color2: "#f3994e",
  },
  {
    name: "Marketing",
    percentage: 5,
    color1: "#FF0000",
    color2: "#FF0000",
  },
  {
    name: "Team",
    percentage: 5,
    color1: "#5c36bd",
    color2: "#b31efc",
  },
];
