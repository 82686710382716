const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">
        <span className="not-found-text">Oops!</span>
      </h1>
      <h2 className="not-found-subtitle">404 - PAGE NOT FOUND</h2>
      <p className="not-found-message">
        The page you are looking for might have been removed
        had its name changed or is temporarily unavailable.
      </p>
      <a href="/" className="home-button">GO TO HOMEPAGE</a>
    </div>
  );
};

export default NotFoundPage;
