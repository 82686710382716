import React, { useEffect, useState } from "react";
import { AutoPumpTokenActions } from "../../../actions/AutoPumpTokenActions";
import CustomWalletProvider from "../../../web3/custom-wallet-provider";
import ProgressBar from "../progress-bar/ProgressBar";

type Props = {};

const ThresholdProgress = (props: Props) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const getAutopumpThresholdPercentage = async () => {
      const alchemyProvider = CustomWalletProvider.getProvider();
      const res = await AutoPumpTokenActions.getAutopumpThresholdPercentage(
        alchemyProvider
      );      
      setPercentage(res);
    };
    getAutopumpThresholdPercentage()
  }, []);
  return <ProgressBar percentage={percentage} header="AutoPump Progress"/>;
};

export default ThresholdProgress;
