import MainBannerMintCounter from "../../organisms/MainBannerMintCounter";
import BannerButtons from "./BannerButtons";
import ThresholdProgress from "./ThresholdProgres";


type Props = {};

const LeftBanner = (props: Props) => {
  return (
    <div className="autopump_v1_baner_left autopump_v4_baner_left">
      <h2>
        Welcome To 🎯
        <br />
        AutoPump
      </h2>
      <h4 className="uppercase">Your Ticket to the Moon!</h4>
      <ThresholdProgress/>
      <MainBannerMintCounter  />
      <h5 className="autopump_banner_subtitle">
        Dive into the DeFi space with a coin designed for perpetual pump!
      </h5>
      <div className="autopump_v1_baner_buttons mt-43">
        <BannerButtons />
      </div>
    </div>
  );
};

export default LeftBanner;
