import sushiswap from "../../assets/images/sushiswap-logo.png";
import uniswap from "../../assets/images/uniswap-logo.png";
import auditby from "../../assets/images/icon/IB audit Stamp 1.1.svg";
import base from "../../assets/images/base-logo (1).png";
import DEXTools_black from "../../assets/images/icon/DEXTools_ white.png";
import SectionHeader from "../molecules/shared/SectionHeader";
import { truncateAddress } from "../../utils/truncateAddress";
import Burn from "../molecules/shared/Burn";

type Props = {};

const TokenSection = (props: Props) => {
  return (
    <div style={{ paddingBlock: "70px" }}>
      <div className="tokens-header">
        <div>
          <SectionHeader title="" subtitle="Tokens" />
        </div>
        <a
          href={require("../../assets/images/autopump-audit.pdf")}
          download="autopump-audit.pdf"
        >
          <img
            src={auditby}
            alt="AuditBy"
            width="60px"
            style={{ marginTop: "25px" }}
          />
        </a>
      </div>
      <div className="tokens">
        <div className="swaps-logos">
          <a href="https://www.dextools.io/app/en/base/pair-explorer/0x01204ea51961591236000cc709cd79db16069369?t=1712606386283">
            <img src={sushiswap} alt="SushiSwap" />
          </a>
          <a
            href="https://www.dextools.io/app/en/base/pair-explorer/0xe7cc983d87777b51137e6cf88d7a054da0c9db76?t=1712606359602"
            target="_blank"
            rel="noreferrer"
          >
            <img src={uniswap} alt="UniSwap" />
          </a>
          <img src={base} alt="Base" className="base-image" />
          <a
            href="https://www.dextools.io/app/en/base/pair-explorer/0xe7cc983d87777b51137e6cf88d7a054da0c9db76?t=1712606359602"
            target="_blank"
            rel="noreferrer"
          >
            <img src={DEXTools_black} alt="" />
          </a>
        </div>

        <Burn />
        <h4 style={{ color: "white" }}>BURNED TOKENS</h4>
        <div className="contract-address">
          <span
            style={{
              padding: "10px",
              color: "#00ffa3",
              fontFamily: "Bakbak One",
              letterSpacing: "1px",
              fontSize: "20px",
            }}
          >
            Contract Address:
          </span>
          <a
            href="https://basescan.org/address/0xEB319ea07cA67a9D96d57DE91503F85E4fe386B3"
            target="_blank"
            rel="noreferrer"
          >
            {truncateAddress("0xEB319ea07cA67a9D96d57DE91503F85E4fe386B3")}
          </a>
        </div>

        <span style={{ fontWeight: "bold" }}>Ownership Renounced</span>
      </div>
    </div>
  );
};

export default TokenSection;
