import { useEffect, useState } from "react";
import AutoPumpImage from "../../assets/images/logo.svg";
import v4_baner_mesh_grad from "../../assets/images/nft/v4_baner_mesh-grad.png";
import InvestorInfo from "../molecules/mint/InvestorInfo";
import { AutoPumpPresaleActions } from "../../actions/AutoPumpPresaleActions";
import CustomWalletProvider from "../../web3/custom-wallet-provider";
import ClaimButton from "../molecules/mint/ClaimButton";
import LockUpCounter from "./LockUpCounter";
import dayjs from "dayjs";
import MintSectionHeader from "../molecules/mint/mintSectionHeader";
import WithDrawalCounter from "./WithDrawalCounter";
import utc from "dayjs/plugin/utc";
import BuyNow from "../molecules/mint/BuyNow";
import { formatTimestampToDate } from "../../utils/formatTimestampToDate";
import { useDispatch, useSelector } from "react-redux";
import { setPresaleClosed } from "../../redux/slices/presaleSlice";
import { RootState } from "../../redux/store";
import { setlockupPeriod } from "../../redux/slices/lockupPeriodSlice";
import {
  InvestorInfo as InvestorInfoInterface,
  fetchInvestorInfo,
} from "../../utils/fetchInvestorInfo";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  setContribution,
  setEligibleTokens,
  setTokenAddress,
  setTokenBalance,
  setTotalTokensWithdrawn,
} from "../../redux/slices/investorInfo";
import { fetchStaticsData } from "../../utils/fetchStaticsData";
import {
  setFundraisingGoal,
  setRaisedAmount,
  setPrice,
} from "../../redux/slices/staticsSlice";
import { fetchProgressBarData } from "../../utils/fetchProgressBarData";
import { setPercentage } from "../../redux/slices/progressBarSlice";
import { switchToTargetedNetwork } from "../../helpers/SwitchNetworkHelper";
import { setIsLoading } from "../../redux/slices/loaderSlice";
import Loader from "../molecules/loader/Loader";

dayjs.extend(utc);

type Props = {};

let count = 0;

const MintSection = (props: Props) => {
  const alchemyProvider = CustomWalletProvider.getProvider();

  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected, chainId } = useWeb3ModalAccount();

  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const presaleClosed = useSelector(
    (state: RootState) => state.presale.presaleClosed
  );

  const lockUpPeriod = useSelector(
    (state: RootState) => state.lockup.lockupPeriod
  );

  const [isAfterLockUpEnd, setIsAfterLockUpEnd] = useState<boolean>(false);

  const now = dayjs.utc();

  const lockUpEndDate = dayjs.utc(lockUpPeriod);

  useEffect(() => {
    const initialize = async () => {
      try {
        const presaleCloseStatus = await AutoPumpPresaleActions.presaleClosed(
          alchemyProvider
        );
        dispatch(setPresaleClosed(presaleCloseStatus));

        const { raisedAmount, fundraisingGoal, rate, token, presaleClosed } =
          await fetchStaticsData(alchemyProvider);
        const { percentage } = await fetchProgressBarData(alchemyProvider);

        const LOCKUP_PERIOD_DAYS =
          await AutoPumpPresaleActions.LOCKUP_PERIOD_DAYS(alchemyProvider);
        const closedPresaleTime =
          await AutoPumpPresaleActions.closedPresaleTime(alchemyProvider);
        const timestampInSeconds =
          Number(LOCKUP_PERIOD_DAYS) + Number(closedPresaleTime);
        const formattedDate = formatTimestampToDate(timestampInSeconds);
        setIsAfterLockUpEnd(now.isAfter(formattedDate));
        dispatch(setlockupPeriod(formattedDate));

        if (
          isConnected &&
          walletProvider != null &&
          chainId != null &&
          address != null
        ) {
          switchToTargetedNetwork(walletProvider, chainId);
          const investorInfo = await fetchInvestorInfo(
            alchemyProvider,
            address
          );
          dispatch(setTokenBalance(Number(investorInfo.tokenBalance)));
          dispatch(
            setTotalTokensWithdrawn(Number(investorInfo.TotalTokensWithdrawn))
          );
          dispatch(
            setContribution(Number(investorInfo.balance) / Number(rate))
          );
          dispatch(setEligibleTokens(Number(investorInfo.eligibleTokens)));
          dispatch(setPrice(Number(rate)));
          dispatch(setTokenAddress(token));
          dispatch(setPresaleClosed(presaleClosed));
          dispatch(setRaisedAmount(raisedAmount));
          dispatch(setFundraisingGoal(fundraisingGoal));
          dispatch(setPercentage(percentage));
          dispatch(setIsLoading(false));
        } else {
          dispatch(setIsLoading(false));
        }
      } catch (error) {
        console.error("Error fetching investor info:", error);
      }
    };

    if (alchemyProvider) {
      initialize();
    }
  }, [
    address,
    chainId,
    isConnected,
    walletProvider,
    dispatch,
    alchemyProvider,
  ]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div
      className="container mint-section"
      style={{ paddingBlock: "20px", minHeight: "100vh" }}
    >
      <MintSectionHeader />
      <div className="autopump_mint_body_content">
        <div className="row">
          <div className="col-md-6">
            <div className="autopump_mint1_body_right">
              <div className="mint1_mash_gard">
                <span>
                  <img src={v4_baner_mesh_grad} alt="img" />
                </span>
              </div>
              <h1 className="banner-title uppercase">AUTOPUMP PRESALE</h1>
              {isAfterLockUpEnd ? <WithDrawalCounter /> : <LockUpCounter />}
            </div>
          </div>
          <div className="col-md-5">
            <div className="autopump_mint1_body_left">
              <div className="autopump_mint1_body_left_content">
                <div className="mint-item_img">
                  <div className="mint-slideshow-container">
                    <img src={AutoPumpImage} alt="img" className="img-fluid" />
                  </div>
                  <br />
                </div>

                <InvestorInfo />

                {/* {!presaleClosed && <BuyNow />} */}
                {presaleClosed && lockUpEndDate < now && <ClaimButton />}

                <a
                  href="https://basescan.org/address/0x5948801678C71A3C37B486d15dCC43a43F76f8F9"
                  target="_blank"
                  rel="noreferrer"
                  className="green base-can-link"
                >
                  <i className="fa-solid fa-square-up-right"></i> Go to basescan
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-md-11"
            style={{ textAlign: "center", marginTop: "40px" }}
          >
            <span>
              <strong className="uppercase">Lockup & Release : </strong>
              Your AUTO tokens will be held for 7 days after purchase before
              gradually releasing over 69 days to ensure a steady circulation.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintSection;
