import PriceSymbol from "../mint/PriceSymbole";

export const formatCompactNumber = (number: number) => {  
  if (number < 1000) {
    return <>{number}</>;
  } else if (number >= 1000 && number < 1_000_000) {
    return (
      <>
        {(number / 1000).toFixed(2)} <PriceSymbol symbol="k" />
      </>
    );
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (
      <>
        {(number / 1_000_000).toFixed(2)}
        <PriceSymbol symbol="M" />
      </>
    );
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (
      <>
        {(number / 1_000_000_000).toFixed(2)}
        <PriceSymbol symbol="B" />
      </>
    );
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return(<>
      {(number / 1_000_000_000_000).toFixed(2)}
      <PriceSymbol symbol="T" />
    </>);
  }
};
