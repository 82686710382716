import SectionHeader from "../molecules/shared/SectionHeader";
import { teamMembers } from "../../constants/TeamMember";
import TeamMemberCard from "../molecules/team-member/TeamMemberCard";
import team_bear_img from "../../assets/images/bg/road_map_avatar_image.webp";

const TeamSection = () => {
  return (
    <>
      <div className="autopump_team_sect v2_team_sect" id="team">
        <div className="team_bear_img">
          <img src={team_bear_img} alt="team bear img" />
        </div>
        <div className="v2_team_overlay">
          <div className="container">
            <SectionHeader title="Team Members" subtitle="Meet The Crew" />
            <div className="autopump_team_content">
              <div className="row">
                {teamMembers.map((member) => (
                  <TeamMemberCard
                    key={member.id}
                    src={member.src}
                    alt={member.alt}
                    name={member.name}
                    role={member.role}
                  />
                ))}
              </div>
              <h5 className="team-description-text">Our team, currently employed at top 50 crypto companies, remains anonymous to maintain our professional commitments as we contribute to AutoPump's innovation.</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamSection;
