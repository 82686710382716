import { ethers, BigNumberish } from "ethers";
import { AutoPumpPresale } from "../web3/AutoPumpPresale";
import { CONTRACTS } from "../config/web3";

class AutoPumpPresaleActions {
  // Retrieves the lockup period in days from the presale contract.
  static async LOCKUP_PERIOD_DAYS(provider: ethers.Provider): Promise<Number> {
    const lockUpPeriod = await AutoPumpPresale.LOCKUP_PERIOD_DAYS(
      provider,
      CONTRACTS.autoPumpPresale
    );

    return Number(lockUpPeriod.toString());
  }
  // Retrieves the precision multiplier from the presale contract. Useful for calculations.
  static async PRECISION_MULTIPLIER(
    provider: ethers.Provider
  ): Promise<number> {
    return AutoPumpPresale.PRECISION_MULTIPLIER(
      provider,
      CONTRACTS.autoPumpPresale
    );
  }

  // Retrieves the withdrawal period in days from the presale contract.
  static async WITHDRAWAL_PERIOD_DAYS(
    provider: ethers.Provider
  ): Promise<number> {
    const minimuimPeriodDays = await AutoPumpPresale.WITHDRAWAL_PERIOD_DAYS(
      provider,
      CONTRACTS.autoPumpPresale
    );
    return Number(minimuimPeriodDays.toString());
  }

  // Allows a buyer to purchase tokens by sending ETH to the presale contract.
  static async buyTokens(
    signer: ethers.Signer,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const parsedValue = ethers.parseEther(value.toString());

    return AutoPumpPresale.buyTokens(
      signer,
      CONTRACTS.autoPumpPresale,
      parsedValue
    );
  }

  // Retrieves the token balance and the total tokens withdrawn by a buyer.
  static async buyers(
    provider: ethers.Provider,
    buyerAddress: string
  ): Promise<{
    tokenBalance: BigNumberish;
    totalTokensWithdrawn: BigNumberish;
  }> {
    return AutoPumpPresale.buyers(
      provider,
      CONTRACTS.autoPumpPresale,
      buyerAddress
    );
  }

  // Calculates the number of eligible tokens for withdrawal by a buyer.
  static async calculateEligibleTokens(
    provider: ethers.Provider,
    buyerAddress: string
  ): Promise<Number> {
    const eligibleTokens = await AutoPumpPresale.calculateEligibleTokens(
      provider,
      CONTRACTS.autoPumpPresale,
      buyerAddress
    );
    return Number(ethers.formatEther(eligibleTokens.toString()));

  }

  // Closes the presale, preventing any further token purchases.
  static async closePresale(
    signer: ethers.Signer
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.closePresale(signer, CONTRACTS.autoPumpPresale);
  }

  // Retrieves the timestamp when the presale was closed.
  static async closedPresaleTime(provider: ethers.Provider): Promise<Number> {
    const closePresaleTimeResponse = await AutoPumpPresale.closedPresaleTime(
      provider,
      CONTRACTS.autoPumpPresale
    );

    return Number(closePresaleTimeResponse.toString());
  }

  // Retrieves the fundraising goal set for the presale.
  static async fundraisingGoal(provider: ethers.Provider): Promise<Number> {
    const fundraisingGoalAmount = await AutoPumpPresale.fundraisingGoal(
      provider,
      CONTRACTS.autoPumpPresale
    );

    return Number(ethers.formatEther(fundraisingGoalAmount));
  }

  // Retrieves the current token balance of a buyer.
  static async getTokenBalance(
    provider: ethers.Provider,
    buyerAddress: string
  ): Promise<Number> {
    const tokenBalance = await AutoPumpPresale.getTokenBalance(
      provider,
      CONTRACTS.autoPumpPresale,
      buyerAddress
    );

    const formattedTokenBalance = ethers.formatEther(tokenBalance);

    return Number(formattedTokenBalance);
  }

  // Retrieves the total amount of tokens a buyer has withdrawn.
  static async getTotalTokensWithdrawn(
    provider: ethers.Provider,
    buyerAddress: string
  ): Promise<Number> {
    const totalTokensWithdrawn = await AutoPumpPresale.getTotalTokensWithdrawn(
      provider,
      CONTRACTS.autoPumpPresale,
      buyerAddress
    );

    const formattedTotalTokensWithdrawn =
      ethers.formatEther(totalTokensWithdrawn);

    return Number(formattedTotalTokensWithdrawn);
  }

  // Opens the presale for token purchases.
  static async openPresale(
    signer: ethers.Signer
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.openPresale(signer, CONTRACTS.autoPumpPresale);
  }

  // Retrieves the owner of the presale contract.
  static async owner(provider: ethers.Provider): Promise<string> {
    return AutoPumpPresale.owner(provider, CONTRACTS.autoPumpPresale);
  }

  // Checks if the presale has been officially closed.
  static async presaleClosed(provider: ethers.Provider): Promise<boolean> {
    return AutoPumpPresale.presaleClosed(provider, CONTRACTS.autoPumpPresale);
  }

  // Retrieves the total amount of funds raised during the presale.
  static async raisedAmount(provider: ethers.Provider): Promise<Number> {
    const raised = await AutoPumpPresale.raisedAmount(
      provider,
      CONTRACTS.autoPumpPresale
    );

    return Number(ethers.formatEther(raised));
  }

  // Retrieves the rate at which ETH is converted to the presale tokens.
  static async rate(provider: ethers.Provider): Promise<Number> {
    const rate = await AutoPumpPresale.rate(
      provider,
      CONTRACTS.autoPumpPresale
    );

    return Number(ethers.formatUnits(rate, 2));
  }

  // Allows the current owner to renounce their ownership of the presale contract, transferring it to the zero address.
  static async renounceOwnership(
    signer: ethers.Signer
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.renounceOwnership(signer, CONTRACTS.autoPumpPresale);
  }

  // Updates the treasury wallet address where collected funds are sent.
  static async setTreasuryWallet(
    signer: ethers.Signer,
    newWalletAddress: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.setTreasuryWallet(
      signer,
      CONTRACTS.autoPumpPresale,
      newWalletAddress
    );
  }

  // Retrieves the address of the token being sold in the presale.
  static async token(provider: ethers.Provider): Promise<string> {
    return AutoPumpPresale.token(provider, CONTRACTS.autoPumpPresale);
  }

  // Transfers ownership of the presale contract to a new address.
  static async transferOwnership(
    signer: ethers.Signer,
    newOwner: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.transferOwnership(
      signer,
      CONTRACTS.autoPumpPresale,
      newOwner
    );
  }

  // Retrieves the treasury wallet address.
  static async treasuryWallet(provider: ethers.Provider): Promise<string> {
    return AutoPumpPresale.treasuryWallet(provider, CONTRACTS.autoPumpPresale);
  }

  // Withdraws specified amount of ETH to a given address, typically for refund or treasury management purposes.
  static async withdraw(
    signer: ethers.Signer,
    toAddress: string,
    amount: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.withdraw(
      signer,
      CONTRACTS.autoPumpPresale,
      toAddress,
      amount
    );
  }

  // Allows buyers to withdraw their purchased tokens after the lockup period has ended.
  static async withdrawTokens(
    signer: ethers.Signer
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpPresale.withdrawTokens(signer, CONTRACTS.autoPumpPresale);
  }
}

export { AutoPumpPresaleActions };
