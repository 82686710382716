import { BigNumberish, ethers } from "ethers";
import { ABIS } from "../config/web3";

class AutoPumpToken {
  // Creates a contract instance using either a Signer or Provider and the contract address.
  static contractInstance(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): ethers.Contract {
    return new ethers.Contract(
      contractAddress,
      ABIS.autoPumpTokenABI,
      ethersSigner
    );
  }

  // Retrieves the balance of a given account.
  static async balanceOf(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string,
    account: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.balanceOf(account);
  }

  // Transfers tokens from the signer's account to another account.
  static async transfer(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    to: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.transfer(to, value);
  }

  // Approves a spender to withdraw from the signer's account, multiple times, up to the value amount.
  static async approve(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    spender: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.approve(spender, value);
  }

  // Sets the fees for transactions within the contract.
  static async setFees(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    _fees: {
      burnFee: BigNumberish;
      pumpFee: BigNumberish;
      liquifyFee: BigNumberish;
    }
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setFees(_fees);
  }

  // Returns the name of the token.
  static async getName(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.name();
  }

  // Enables or disables the pump mechanism, which could be a specific functionality within the contract.
  static async setPumpEnabled(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    enabled: boolean
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setPumpEnabled(enabled);
  }

  // Checks if the swap and liquify feature is enabled.
  static async swapAndLiquifyEnabled(
    ethersSigner: ethers.Signer,
    contractAddress: string
  ): Promise<boolean> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.swapAndLiquifyEnabled();
  }

  // Updates the router address used for swapping and liquidity operations.
  static async setRouterAddress(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    newRouter: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setRouterAddress(newRouter);
  }

  // Similar to setRouterAddress but for a secondary router, if the contract interacts with multiple routers.
  static async setRouterAddress2(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    newRouter: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setRouterAddress2(newRouter);
  }

  // Sets the threshold at which the tokens start to liquify.
  static async setLiquifyThreshold(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    amountToUpdate: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setLiquifyThreshold(amountToUpdate);
  }

  // Retrieves the current threshold for ETH to trigger a pump mechanism.
  static async pumpEthThreshold(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.pumpEthThreshold();
  }

  // Enables or disables the swap and liquify functionality.
  static async setSwapAndLiquifyEnabled(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    enabled: boolean
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setSwapAndLiquifyEnabled(enabled);
  }

  // Transfers the ownership of the contract to a new address.
  static async transferOwnership(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    newOwner: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.transferOwnership(newOwner);
  }

  // Retrieves the current fee structure of the contract.
  static async getFees(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<{
    burnFee: BigNumberish;
    pumpFee: BigNumberish;
    liquifyFee: BigNumberish;
  }> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.fees();
  }

  // Allows sending Ether to the contract, potentially to a payable fallback or receive function.
  static async depositEth(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    amount: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.deposit({ value: amount });
  }

  // Checks if a specific address is excluded from paying fees.
  static async isExcludedFromFee(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string,
    account: string
  ): Promise<boolean> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract._isExcludedFromFee(account);
  }

  // Excludes or includes an address in the fee structure.
  static async setExcludeFromFee(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    account: string,
    status: boolean
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setExcludeFromFee(account, status);
  }

  // Retrieves the address of the Uniswap V2 Pair.
  static async uniswapV2Pair(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.uniswapV2Pair();
  }

  // Retrieves the address of the Uniswap V2 Router.
  static async uniswapV2Router(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.uniswapV2Router();
  }

  // Similar to uniswapV2Router, potentially for a secondary or alternative router.
  static async uniswapV2Router2(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.uniswapV2Router2();
  }

  // Allows the current owner to renounce ownership, transferring it to the zero address.
  static async renounceOwnership(
    ethersSigner: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.renounceOwnership();
  }

  // Retrieves the number of decimals used to get its user representation.
  static async decimals(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<number> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.decimals();
  }

  // Retrieves the address of the current owner.
  static async owner(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.owner();
  }

  // Retrieves the symbol of the token.
  static async symbol(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.symbol();
  }

  // Retrieves the total supply of the token.
  static async totalSupply(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.totalSupply();
  }

  // Transfers tokens from one address to another with allowance check.
  static async transferFrom(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    from: string,
    to: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.transferFrom(from, to, value);
  }

  // Retrieves the BURN_ADDRESS of the token contract.
  static async BURN_ADDRESS(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.BURN_ADDRESS();
  }

  // Checks the allowance an owner provided to a spender.
  static async allowance(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string,
    owner: string,
    spender: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.allowance(owner, spender);
  }

  // Checks if the contract is in swap and liquify mode.
  static async inSwapAndLiquify(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<boolean> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.inSwapAndLiquify();
  }

  // Retrieves the threshold for triggering liquify and swap functionality.
  static async liquifyTokenThreshold(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.liquifyTokenThreshold();
  }

  // Checks if the pump mechanism is enabled in the contract.
  static async pumpEnabled(
    ethersSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<boolean> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.pumpEnabled();
  }

  // Sets the threshold for the pump mechanism activation in terms of ETH.
  static async setPumpThreshold(
    ethersSigner: ethers.Signer,
    contractAddress: string,
    amountToUpdate: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(ethersSigner, contractAddress);
    return await contract.setPumpThreshold(amountToUpdate);
  }
}

export { AutoPumpToken };