import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import { CHAIN_IDS } from "../config/web3";

export const validateNetworkId = (chainId: number) => {
  const targetNetwork =
    process.env.REACT_APP_API_ENV === "dev"
      ? CHAIN_IDS.mumbaiTestNet
      : CHAIN_IDS.baseMainnet;

  return chainId === parseInt(targetNetwork, 16);
};

export const switchToTargetedNetwork = async (
  walletProvider: Eip1193Provider | undefined,
  chainId: number
) => {
  let ethereum: any = window.ethereum;

  if (validateNetworkId(chainId) === true) {
    return;
  }

  if (walletProvider === undefined) {
    // TODO message box please connect your wallet

    return;
  }

  const targetNetwork =
    process.env.REACT_APP_API_ENV === "dev"
      ? CHAIN_IDS.mumbaiTestNet
      : CHAIN_IDS.baseMainnet;

  // Request network switch
  const provider = new BrowserProvider(walletProvider);

  try {
    // Try switching to the target network
    if (ethereum) {
      await ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            //   chainId: "0x13881",
            chainId: targetNetwork,
          },
        ],
      });
    }
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        // The requested network is not added to the user's wallet, so try to add it
        await provider.send("wallet_addEthereumChain", [targetNetwork]);
      } catch (addError) {
        console.error("Network add error:", addError);
      }
    } else {
      console.error("Network switch error:", switchError);
    }
  }
};
