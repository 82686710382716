import React from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider } from "ethers";
import { AutoPumpPresaleActions } from "../../../actions/AutoPumpPresaleActions";
import { switchToTargetedNetwork } from "../../../helpers/SwitchNetworkHelper";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/slices/ModalSlice";
import ConnectedModal from "../../atoms/ConnectedModal";
import Button from "../../atoms/ui/Button";

type Props = {};

const ClaimButton: React.FC<Props> = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const dispatch = useDispatch();

  const claim = async () => {
    if (
      isConnected &&
      walletProvider != null &&
      chainId != null &&
      address != null
    ) {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      switchToTargetedNetwork(walletProvider, chainId);

      const transaction = await AutoPumpPresaleActions.withdrawTokens(signer);
      if (transaction != null) {
        dispatch(
          openModal({ message: "Successful transaction", status: "success" })
        );
        return;
      }
      dispatch(openModal({ message: "Faild transaction", status: "success" }));
    } else {
      dispatch(
        openModal({
          message: "please connect to you button",
          status: "success",
        })
      );
    }
  };

  return (
    <>
      <Button
        className="btn-mid-green w-100 hov_shape_show"
        text="Claim"
        onClick={() => claim()}
      />
      <ConnectedModal />
    </>
  );
};

export default React.memo(ClaimButton);
