import React from "react";
import SectionHeader from "../molecules/shared/SectionHeader";
import FeatureCard from "../molecules/feature-card/FeatureCard";
import { featureData } from "../../constants/Features";
import animationData from "../../lottie/astronaut.json";
import AnimatedImage from "../atoms/animated-image/AnimatedImage";

type Props = {};

const FeatureSection = (props: Props) => {
  return (
    <div className="triader_benefits_sect" id="features">
      <div className="lottie-feature-image">
        <div style={{ width: "200px", height: "200px" }}>
          <AnimatedImage animationData={animationData} />
        </div>
      </div>
      <div className="container">
        <SectionHeader title="Features" subtitle="AutoPump Advantages" />
        <div className="about_us_text_card_sect triader_benefits_card">
          <div className="row">
            {featureData.map(({ imgSrc, alt, title, description }) => (
              <FeatureCard
                key={description}
                imgSrc={imgSrc}
                alt={alt}
                title={title}
                description={description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
