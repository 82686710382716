import React, { useEffect, useState } from "react";
import mint_right_text_icon from "../../assets/images/icon/mint-right-text-icon.svg";
import { BrowserProvider, ethers } from "ethers";
import { AutoPumpPresaleActions } from "../../actions/AutoPumpPresaleActions";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import CustomWalletProvider from "../../web3/custom-wallet-provider";
import SmallImage from "./ui/SmallImage";

type Props = {
  soldOut: boolean;
};

const PresaleStatusIndicator: React.FC<Props> = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();

  const [presaleClosed, setPresaleClosed] = useState<boolean>(false);

  async function getPresaleClosed(provider: ethers.Provider) {
    const presaleCloseStatus = await AutoPumpPresaleActions.presaleClosed(
      provider
    );
    setPresaleClosed(presaleCloseStatus);
  }

  useEffect(() => {
    // if (isConnected && walletProvider != null) {
    // const provider = new BrowserProvider(walletProvider)

    const alchemyProvider = CustomWalletProvider.getProvider();
    getPresaleClosed(alchemyProvider);

    // }
  }, [address, isConnected, walletProvider]);

  return (
    <h4 className="mb-15 uppercase">
      Presale :
      <span className={presaleClosed ? "red" : "green"}>
        {presaleClosed ? " SOLDOUT " : " OPEN "}
      </span>
      <SmallImage className="presale-status-indicator-icon-wrapper" src={mint_right_text_icon} alt="check-icon"/>
    </h4>
  );
};

export default PresaleStatusIndicator;
