import { useState, useEffect } from "react";

// Countdown function
const useCountdown = (targetDate: string | undefined) => {
  // Initialize countdown to zero by default
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    // Ensure targetDate is not undefined before proceeding
    if (targetDate === undefined) {
      return;
    }

    const countDownDate = new Date(targetDate).getTime();
    const now = new Date().getTime();
    const initialDistance = countDownDate - now;

    // Set initial countdown value or keep it at zero if the date has passed
    setCountDown(Math.max(initialDistance, 0));

    // Check if the date is already exceeded before starting the interval
    if (initialDistance <= 0) {
      return;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance <= 0) {
        clearInterval(interval);
        setCountDown(0);
      } else {
        setCountDown(distance);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]); // Dependency on targetDate only, as countDown state will be set internally

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // Return zeros if countdown is finished or hasn't been started
  if (countDown <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export default useCountdown;
