import { ethers, BigNumberish } from "ethers";
import { ABIS } from "../config/web3"; // Ensure this path is correct for your project setup.

class AutoPumpPresale {
  // Initializes a contract instance with the provided signer or provider.
  static contractInstance(
    providerOrSigner: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): ethers.Contract {
    return new ethers.Contract(
      contractAddress,
      ABIS.autoPumpPresaleABI,
      providerOrSigner
    );
  }

  // Retrieves the lock-up period in days for the presale.
  static async LOCKUP_PERIOD_DAYS(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<number> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.LOCKUP_PERIOD_DAYS();
  }

  // Retrieves the precision multiplier for token calculations.
  static async PRECISION_MULTIPLIER(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<number> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.PRECISION_MULTIPLIER();
  }

  // Retrieves the withdrawal period in days post-presale.
  static async WITHDRAWAL_PERIOD_DAYS(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<number> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.WITHDRAWAL_PERIOD_DAYS();
  }

  // Allows a buyer to purchase tokens by sending ETH.
  static async buyTokens(
    signer: ethers.Signer,
    contractAddress: string,
    value: BigNumberish // Specify the amount of ETH to send.
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.buyTokens({ value });
  }

  // Retrieves the token balance and total tokens withdrawn for a buyer.
  static async buyers(
    provider: ethers.Provider,
    contractAddress: string,
    buyerAddress: string
  ): Promise<{
    tokenBalance: BigNumberish;
    totalTokensWithdrawn: BigNumberish;
  }> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.buyers(buyerAddress);
  }

  // Calculates the eligible tokens for a buyer based on the amount they contributed.
  static async calculateEligibleTokens(
    provider: ethers.Provider,
    contractAddress: string,
    buyerAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.calculateEligibleTokens(buyerAddress);
  }

  // Closes the presale, preventing further purchases.
  static async closePresale(
    signer: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.closePresale();
  }

  // Retrieves the timestamp when the presale was closed.
  static async closedPresaleTime(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.closedPresaleTime();
  }

  // Retrieves the fundraising goal of the presale.
  static async fundraisingGoal(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.fundraisingGoal();
  }

  // Retrieves the token balance for a specific buyer.
  static async getTokenBalance(
    provider: ethers.Provider,
    contractAddress: string,
    buyerAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.getTokenBalance(buyerAddress);
  }

  // Retrieves the total amount of tokens a buyer has withdrawn.
  static async getTotalTokensWithdrawn(
    provider: ethers.Provider,
    contractAddress: string,
    buyerAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.getTotalTokensWithdrawn(buyerAddress);
  }

  // Opens the presale, allowing purchases.
  static async openPresale(
    signer: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.openPresale();
  }

  // Retrieves the owner of the presale contract.
  static async owner(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.owner();
  }

  // Checks if the presale has been closed.
  static async presaleClosed(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<boolean> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.presaleClosed();
  }

  // Retrieves the total amount of funds raised during the presale.
  static async raisedAmount(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.raisedAmount();
  }

  // Retrieves the rate at which ETH is converted to tokens.
  static async rate(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.rate();
  }

  // Allows the current owner to renounce their ownership of the contract.
  static async renounceOwnership(
    signer: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.renounceOwnership();
  }

  // Updates the treasury wallet address where funds are collected.
  static async setTreasuryWallet(
    signer: ethers.Signer,
    contractAddress: string,
    newWalletAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.setTreasuryWallet(newWalletAddress);
  }

  // Retrieves the token contract address associated with the presale.
  static async token(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.token();
  }

  // Transfers ownership of the contract to a new address.
  static async transferOwnership(
    signer: ethers.Signer,
    contractAddress: string,
    newOwner: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.transferOwnership(newOwner);
  }

  // Retrieves the treasury wallet address.
  static async treasuryWallet(
    provider: ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    const contract = this.contractInstance(provider, contractAddress);
    return contract.treasuryWallet();
  }

  // Withdraws funds to a specified address.
  static async withdraw(
    signer: ethers.Signer,
    contractAddress: string,
    toAddress: string,
    amount: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.withdraw(toAddress, amount);
  }

  // Allows buyers to withdraw their purchased tokens after the lockup period.
  static async withdrawTokens(
    signer: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    const contract = this.contractInstance(signer, contractAddress);
    return contract.withdrawTokens();
  }
}

export { AutoPumpPresale };