import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InvestorInfoState {
  tokenBalance: number;
  contribution: number;
  totalTokensWithdrawn: number;
  tokenAddress: string;
  presaleClosed: boolean;
  eligibleTokens:number
}

const initialState: InvestorInfoState = {
  tokenBalance: 0,
  contribution: 0,
  totalTokensWithdrawn: 0,
  tokenAddress: "",
  presaleClosed: true,
  eligibleTokens: 0,
};

export const investorInfoSlice = createSlice({
  name: "investorInfo",
  initialState,
  reducers: {
    setTokenBalance: (state, action: PayloadAction<number>) => {
      state.tokenBalance = action.payload;
    },
    setContribution: (state, action: PayloadAction<number>) => {
      state.contribution = action.payload;
    },
    setTotalTokensWithdrawn: (state, action: PayloadAction<number>) => {
      state.totalTokensWithdrawn = action.payload;
    },
    setTokenAddress: (state, action: PayloadAction<string>) => {
      state.tokenAddress = action.payload;
    },
    setPresaleClosed: (state, action: PayloadAction<boolean>) => {
      state.presaleClosed = action.payload;
    },
    setEligibleTokens: (state, action: PayloadAction<number>) => {
      state.eligibleTokens = action.payload;
    },
  },
});

export const {
  setTokenBalance,
  setContribution,
  setTotalTokensWithdrawn,
  setTokenAddress,
  setPresaleClosed,
  setEligibleTokens
} = investorInfoSlice.actions;

export default investorInfoSlice.reducer;
