import lion from "../assets/images/team/lion.png";
import monkey from "../assets/images/team/monkey.png";
import fox from "../assets/images/team/fox.png";
import wolf from "../assets/images/team/wolf.png";

type TeamMember = {
  id: number;
  name: string;
  role: string;
  src: string;
  alt: string;
};

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "Alpha",
    role: "Business Developer",
    src: lion,
    alt: "Alpha",
  },
  {
    id: 2,
    name: "Upilson",
    role: "Blockchain Developer",
    src: monkey,
    alt: "Upilson",
  },
  {
    id: 3,
    name: "Tau",
    role: "Web3 Developer",
    src: fox,
    alt: "Tau",
  },
  {
    id: 4,
    name: "Omicron",
    role: "Full Stack Developer",
    src: wolf,
    alt: "Omicron",
  },
];
