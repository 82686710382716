import { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import MobileHeaderMenu from "./MobileHeaderMenu";
import HeaderRightPart from "./HeaderRightPart";

type Props = {};

const Header = (props: Props) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setScrolled(scrollY >= 5);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const ChangeIsMenueVisble = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <div className={`autopump_header ${scrolled && "sticky"}`} id="navbar">
        <div className="container">
          <div className="autopump_menu_sect">
            <div className="autopump_menu_left_sect">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <HeaderRightPart
              isMenuVisible={isMenuVisible}
              ChangeIsMenueVisble={ChangeIsMenueVisble}
            />
          </div>
          <div
            className="autopump_mobile_menu"
            style={{ display: isMenuVisible ? "block" : "none" }}
          >
            <MobileHeaderMenu ChangeIsMenueVisble={ChangeIsMenueVisble} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
