import React from "react";

type Props = {
  ChangeIsMenueVisble: () => void;
};

const CloseButton: React.FC<Props> = ({
  ChangeIsMenueVisble,
}) => {
  return (
    <button className="mobile_menu_close_btn" onClick={ChangeIsMenueVisble}>
      <i className="fa-solid fa-xmark"></i>
    </button>
  );
};

export default CloseButton;
