import React from "react";
import ButtonLink from "../../atoms/ui/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const BannerButtons: React.FC = () => {
  return (
    <div className="join_comunity_btns" style={{ margin: "0px" }}>
      <ButtonLink
        buttonText="Whitepaper"
        buttonClass="wishlist_btn"
        url="https://autopump.gitbook.io/litepaper"
      />
      <ButtonLink
        buttonText="GitHub Code"
        buttonClass="widhlist_btn"
        icon={<FontAwesomeIcon icon={faGithub} />}
        url="https://github.com/autopump"
      />
    </div>
  );
};

export default BannerButtons;
