import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isOpen: boolean;
  message: string;
  status: 'success' | 'fail' | null; // Add this line
}

const initialState: ModalState = {
  isOpen: false,
  message: '',
  status: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ message: string; status: 'success' | 'fail' }>) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.status = action.payload.status; // Set the status
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.message = '';
      state.status = null; // Reset the status
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;