import React from 'react'
import AnimatedButton from '../animated-button/AnimatedButton'
import sushiSwap from "../../../assets/images/icon/sushiSwap.svg"
import uniSwap from "../../../assets/images/icon/uniswap.svg"

type Props = {}

const SwapingButtons = (props: Props) => {
  return (
    <div style={{display:"flex",gap:"10px",marginBlock:"10px"}} className='swaping-buttons'>
        <AnimatedButton text='uniswap' url="https://www.dextools.io/app/en/base/pair-explorer/0xe7cc983d87777b51137e6cf88d7a054da0c9db76?t=1712606359602" className='uniswap' imgSrc={uniSwap}/>
        <AnimatedButton text='sushiswap' url="https://www.dextools.io/app/en/base/pair-explorer/0x01204ea51961591236000cc709cd79db16069369?t=1712606386283" className='sushiswap' imgSrc={sushiSwap}/>
    </div>
  )
}

export default SwapingButtons