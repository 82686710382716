import React from "react";
import useCountdown from "../../../hooks/useCountdown";

type Props = {
  date: string | undefined;
};

const DateCountDown = (props: Props) => {  
  const { days, hours, minutes, seconds } = useCountdown(props.date);

  return (
    <div className="timer timer_1">
      <ul>
        <li className="days">
          {days < 10 ? "0" : ""}
          {days}
          <span>D</span>
        </li>
        <li className="hours">
          {hours < 10 ? "0" : ""}
          {hours}
          <span>H</span>
        </li>
        <li className="minutes">
          {minutes < 10 ? "0" : ""}
          {minutes}
          <span>M</span>
        </li>
        <li className="seconds">
          {seconds < 10 ? "0" : ""}
          {seconds}
          <span>S</span>
        </li>
      </ul>
    </div>
  );
};

export default DateCountDown;
