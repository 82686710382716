type ListItemProps = {
  title: string;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({ title, className = "" }) => (
  <li className={className}>
    <i className="fas fa-arrow-right"></i>
    <b>{title}</b>
  </li>
);

export default ListItem;
