import React, { useCallback, useEffect, useState } from "react";
import Heading from "../../atoms/mint/Heading";
import { Counter } from "./Counter";
import { ListItem } from "../../atoms/mint/ListItem";

import TruncateText from "../../atoms/ui/TruncateText";
import { formatCompactNumber } from "../../atoms/ui/FormatNumer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const InvestorInfo = () => {
  const [isCopied, setIsCopied] = useState(false);

  const {
    tokenBalance,
    contribution,
    totalTokensWithdrawn,
    tokenAddress,
    presaleClosed,
    eligibleTokens
  } = useSelector((state: RootState) => state.investorInfo);
  const {
    price
  } = useSelector((state: RootState) => state.statics);

  const quantity = useSelector((state: RootState) => state.counter.value);

  const setIsCopiedTrue = async (tokenAddress: string) => {
    await navigator.clipboard.writeText(tokenAddress);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="mint_count_list mt-0">
      <ul className="mt-10">
        <ListItem
          title="Your Token Balance"
          content={
            <Heading>
              <>{formatCompactNumber(+tokenBalance)} AUTO</>
            </Heading>
          }
        />

        <ListItem
          title="Total Tokens Withdrawn"
          content={
            <Heading>
              <>{formatCompactNumber(+totalTokensWithdrawn)} AUTO</>
            </Heading>
          }
        />
       
        <ListItem
          title="Eligible Tokens"
          content={
            <Heading>
              <>{formatCompactNumber(+eligibleTokens)} AUTO</>
            </Heading>
          }
        />

        <ListItem
          title="Token Address"
          content={
            <Heading>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <TruncateText text={tokenAddress} />
                <button
                  onClick={() => setIsCopiedTrue(tokenAddress)}
                  style={{ backgroundColor: "transparent" }}
                >
                  {isCopied ? (
                    <i className="fa-solid fa-check white"></i>
                  ) : (
                    <i className="fa-regular fa-copy white"></i>
                  )}
                </button>
              </div>
            </Heading>
          }
        />

        <ListItem
          title="Your Contribution"
          content={
            <Heading>
              <span className="green uppercase">{Number(contribution)}</span>{" "}
              &nbsp;
              <> ETH</>
            </Heading>
          }
        />

        {!presaleClosed && (
          <ListItem
            title="Quantity"
            content={
              <>
                <Counter />
                <Heading>
                  <span>
                    {formatCompactNumber(+(Number(price) * quantity))}
                  </span>
                  <span className="uppercase">AUTO</span>
                </Heading>
              </>
            }
          />
        )}
      </ul>
    </div>
  );
};

export default React.memo(InvestorInfo);
