import { BigNumberish, ethers } from "ethers";
import { AutoPumpToken } from "../web3/AutoPumpToken";
import { AutoPumpPresale } from "../web3/AutoPumpPresale";
import { CONTRACTS } from "../config/web3";

class AutoPumpTokenActions {
  static async balanceOf(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string,
    account: string
  ): Promise<BigNumberish> {
    return AutoPumpToken.balanceOf(provider, contractAddress, account);
  }

  static async transfer(
    signer: ethers.Signer,
    contractAddress: string,
    to: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.transfer(signer, contractAddress, to, value);
  }

  static async approve(
    signer: ethers.Signer,
    contractAddress: string,
    spender: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.approve(signer, contractAddress, spender, value);
  }

  static async setFees(
    signer: ethers.Signer,
    contractAddress: string,
    fees: {
      burnFee: BigNumberish;
      pumpFee: BigNumberish;
      liquifyFee: BigNumberish;
    }
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setFees(signer, contractAddress, fees);
  }

  static async getName(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.getName(provider, contractAddress);
  }

  static async setPumpEnabled(
    signer: ethers.Signer,
    contractAddress: string,
    enabled: boolean
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setPumpEnabled(signer, contractAddress, enabled);
  }

  static async swapAndLiquifyEnabled(
    provider: ethers.Signer,
    contractAddress: string
  ): Promise<boolean> {
    return AutoPumpToken.swapAndLiquifyEnabled(provider, contractAddress);
  }

  static async setRouterAddress(
    signer: ethers.Signer,
    contractAddress: string,
    newRouter: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setRouterAddress(signer, contractAddress, newRouter);
  }

  static async setRouterAddress2(
    signer: ethers.Signer,
    contractAddress: string,
    newRouter: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setRouterAddress2(signer, contractAddress, newRouter);
  }

  static async setLiquifyThreshold(
    signer: ethers.Signer,
    contractAddress: string,
    amountToUpdate: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setLiquifyThreshold(
      signer,
      contractAddress,
      amountToUpdate
    );
  }

  static async pumpEthThreshold(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    return AutoPumpToken.pumpEthThreshold(provider, contractAddress);
  }

  static async setSwapAndLiquifyEnabled(
    signer: ethers.Signer,
    contractAddress: string,
    enabled: boolean
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setSwapAndLiquifyEnabled(
      signer,
      contractAddress,
      enabled
    );
  }

  static async transferOwnership(
    signer: ethers.Signer,
    contractAddress: string,
    newOwner: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.transferOwnership(signer, contractAddress, newOwner);
  }

  static async getFees(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<{
    burnFee: BigNumberish;
    pumpFee: BigNumberish;
    liquifyFee: BigNumberish;
  }> {
    return AutoPumpToken.getFees(provider, contractAddress);
  }

  static async depositEth(
    signer: ethers.Signer,
    contractAddress: string,
    amount: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.depositEth(signer, contractAddress, amount);
  }

  static async isExcludedFromFee(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string,
    account: string
  ): Promise<boolean> {
    return AutoPumpToken.isExcludedFromFee(provider, contractAddress, account);
  }

  static async setExcludeFromFee(
    signer: ethers.Signer,
    contractAddress: string,
    account: string,
    status: boolean
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setExcludeFromFee(
      signer,
      contractAddress,
      account,
      status
    );
  }

  static async uniswapV2Pair(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.uniswapV2Pair(provider, contractAddress);
  }

  static async uniswapV2Router(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.uniswapV2Router(provider, contractAddress);
  }

  static async uniswapV2Router2(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.uniswapV2Router2(provider, contractAddress);
  }

  static async renounceOwnership(
    signer: ethers.Signer,
    contractAddress: string
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.renounceOwnership(signer, contractAddress);
  }

  static async decimals(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<number> {
    return AutoPumpToken.decimals(provider, contractAddress);
  }

  static async owner(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.owner(provider, contractAddress);
  }

  static async symbol(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.symbol(provider, contractAddress);
  }

  static async totalSupply(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    return AutoPumpToken.totalSupply(provider, contractAddress);
  }

  static async transferFrom(
    signer: ethers.Signer,
    contractAddress: string,
    from: string,
    to: string,
    value: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.transferFrom(signer, contractAddress, from, to, value);
  }

  static async BURN_ADDRESS(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<string> {
    return AutoPumpToken.BURN_ADDRESS(provider, contractAddress);
  }

  static async allowance(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string,
    owner: string,
    spender: string
  ): Promise<BigNumberish> {
    return AutoPumpToken.allowance(provider, contractAddress, owner, spender);
  }

  static async inSwapAndLiquify(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<boolean> {
    return AutoPumpToken.inSwapAndLiquify(provider, contractAddress);
  }

  static async liquifyTokenThreshold(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<BigNumberish> {
    return AutoPumpToken.liquifyTokenThreshold(provider, contractAddress);
  }

  static async pumpEnabled(
    provider: ethers.Signer | ethers.Provider,
    contractAddress: string
  ): Promise<boolean> {
    return AutoPumpToken.pumpEnabled(provider, contractAddress);
  }

  static async setPumpThreshold(
    signer: ethers.Signer,
    contractAddress: string,
    amountToUpdate: BigNumberish
  ): Promise<ethers.ContractTransaction> {
    return AutoPumpToken.setPumpThreshold(
      signer,
      contractAddress,
      amountToUpdate
    );
  }

  static async getAutopumpThresholdPercentage(
    provider: ethers.Provider
  ): Promise<number> {
    const tokenAddress = await AutoPumpPresale.token(
      provider,
      CONTRACTS.autoPumpPresale
    );

    const etherAddressBalance = await provider.getBalance(tokenAddress);
    const formatedEtherBalance = ethers.formatEther(etherAddressBalance);

    const ethThreshold = await AutoPumpToken.pumpEthThreshold(
      provider,
      tokenAddress
    );
    const formatedEthThreshold = ethers.formatEther(ethThreshold);

    if (formatedEthThreshold === "0.0") {
      return 0;
    }
    

    return (
      (Number(formatedEtherBalance.toString()) /
        Number(formatedEthThreshold.toString())) *
      100
    );
  }

  static async getNumberOfTokensBurn(
    provider: ethers.Provider
  ): Promise<number> {
    const OneTrillion = 1_000_000_000_000;

    const tokenAddress = await AutoPumpPresale.token(
      provider,
      CONTRACTS.autoPumpPresale
    );

    const totalSupply = await AutoPumpToken.totalSupply(provider, tokenAddress);
    const formatedTotalSupply = ethers.formatEther(totalSupply);
    
    return OneTrillion - Number(formatedTotalSupply.toString());
  }
}

export { AutoPumpTokenActions };
