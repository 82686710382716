import star_6 from "../../../assets/images/icon/star_6.svg";
import star_1 from "../../../assets/images/icon/star_1.svg";
import star_2 from "../../../assets/images/icon/star_2.svg";
import star_3 from "../../../assets/images/icon/star_3.svg";
import star_4 from "../../../assets/images/icon/star_4.svg";
import star_5 from "../../../assets/images/icon/star_5.svg";

type Props = {};

const AnimatedStars = (props: Props) => {
  return (
    <div className="footer_stras_sect">
      <div className="footer_stars">
        <span className="star_1">
          <img src={star_6} alt="" />
        </span>
        <span className="star_2">
          <img src={star_3} alt="" />
        </span>
        <span className="star_3">
          <img src={star_1} alt="" />
        </span>
        <span className="star_4">
          <img src={star_4} alt="" />
        </span>
        <span className="star_5">
          <img src={star_5} alt="" />
        </span>
        <span className="star_6">
          <img src={star_2} alt="" />
        </span>
        <span className="star_7">
          <img src={star_5} alt="" />
        </span>
      </div>
    </div>
  );
};

export default AnimatedStars;
