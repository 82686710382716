import React from "react";
import Image from "../../atoms/ui/Image";
import spinner from "../../../assets/images/icon/roadmap-spin-icon.png";
import check from "../../../assets/images/icon/roadmap_v2_check.svg";
import ListItem from "../../atoms/roadMap/ListItem";

type Item = {
  title: string;
};

type RoadmapCardProps = {
  phase: string;
  items: Item[];
  index: number;
  isCompleted: boolean;
};

const RoadmapCard: React.FC<RoadmapCardProps> = ({
  phase,
  items,
  index,
  isCompleted,
}) => {
  const cardClass =
    index % 2 === 0 ? "v2_roadmap_card_sect_odd" : "v2_roadmap_card_sect_even";

  return (
    <div className={`col-md-6`}>
      <div className={`v2_roadmap_card_sect ${cardClass}`}>
        <div className="v2_roadmap_card_box"></div>
        <div className="v2_roadmap_card">
          <h3>
            {`Phase ${phase}`}
            <span className={!isCompleted ? "not-completed" : ""}>
              <Image src={isCompleted ? check : spinner} alt="" />
            </span>
          </h3>
          <ul className="road-map-ul">
            {items.map((item) => (
              <ListItem
                title={`${item.title}`}
                className={isCompleted ? "completed" : "not-completed"}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RoadmapCard;
