import footer_shapes_left from "../../../assets/images/icon/footer_shapes_left.png";
import footer_shapes_right from "../../../assets/images/icon/footer_shapes_right.png";

type Props = {};

const CopyRight = (props: Props) => {
  return (
    <div className="autopump_v1_main_footer">
      <div className="autopump_v1_main_footer_overlay"></div>
      <div className="footer_bottom">
        <div className="footer_bottom_content">
          <span className="footer_shapes_left">
            <img src={footer_shapes_left} alt="" />
          </span>
          <span className="footer_shapes_right">
            <img src={footer_shapes_right} alt="" />
          </span>
          <div className="container">
            <div className="footer_menu">
              <div className="bottom_footer_left">
                <div className="copiright_text">
                  Copyright © 2024 AutoPump. All Rights Reserved
                </div>
              </div>
              <a href="#" className="bact_to_top_btn">
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
