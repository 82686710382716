import PresaleStatusIndicator from "../atoms/PresaleStatusIndicator";
import ProgressBar from "../molecules/progress-bar/ProgressBar";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Statics from "../molecules/statics/Statics";
import DateCountDown from "../molecules/shared/DateCountDown";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

dayjs.extend(utc);

const LockUpCounter = () => {
  const percentage = useSelector((state: RootState) => state.progressBar.percentage);

  const presaleClosed = useSelector(
    (state: RootState) => state.presale.presaleClosed
  );

  const lockUpPeriod = useSelector(
    (state: RootState) => state.lockup.lockupPeriod
  );
  const now = dayjs.utc();
  const lockUpEndDate = dayjs.utc(lockUpPeriod);

 

  return (
    <div
      className="container"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="col-lg-12">
        <div className="banner-conent3">
          <PresaleStatusIndicator soldOut={false} />

          <div className="autopump_v3_timer">
            {presaleClosed && lockUpEndDate > now ? (
              <h5 className="uppercase">Lockup ends in</h5>
            ) : (
              <h5 className="uppercase">Claim Your Tokens</h5>
            )}
            {presaleClosed && (
              <DateCountDown date={lockUpPeriod}/>
            )}
          </div>

          <ProgressBar percentage={percentage} header="presale goal"/>

          {!presaleClosed && (
            <div className="banner-bottom-text uppercase">
              Minimum buy is 0.01 <span className="green">ETH</span>
              <br />
              Maximum buy is 0.5 <span className="green">ETH</span>
            </div>
          )}
          <Statics />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default LockUpCounter;
