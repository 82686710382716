import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/quantitySlice";
import modalReducer from "./slices/ModalSlice";
import presaleReducer from "./slices/presaleSlice";
import lockupReducer from "./slices/lockupPeriodSlice";
import investorInfoReducer from "./slices/investorInfo";
import staticsReducer from "./slices/staticsSlice";
import progressBarReducer from "./slices/progressBarSlice";
import loaderReducer from "./slices/loaderSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    modal: modalReducer,
    presale: presaleReducer,
    lockup: lockupReducer,
    investorInfo: investorInfoReducer,
    statics: staticsReducer,
    progressBar: progressBarReducer,
    loader: loaderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
