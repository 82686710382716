import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faTelegramPlane,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import Image from '../ui/Image';
import hov_shape_s from '../../../assets/images/icon/hov_shape_s.svg';

interface SocialLinkProps {
  href: string;
  iconName: 'faXTwitter' | 'faGithub' | 'faTelegramPlane';
  className: string;
}

const iconMap = {
  faXTwitter: faXTwitter,
  faGithub: faGithub,
  faTelegramPlane: faTelegramPlane,
};

const FooterSocialIcon: FC<SocialLinkProps> = ({ href, iconName, className }) => (
  <li className="social_hov_shape_show">
    <a href={href} className={className} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={iconMap[iconName]} />
    </a>
    <span className="social_hov_shape1">
      <Image src={hov_shape_s} alt="" />
    </span>
  </li>
);

export default FooterSocialIcon;

