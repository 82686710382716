import React from "react";


type Props = {
  percentage:number
  header: string
};

const ProgressBar = (props: Props) => {

  return (
    <div className="progress-container">
      <span className="uppercase green">{props.header} : {props.percentage.toFixed(2)}%</span>
      <div className="progress2 progress-moved">
        <div
          className="progress-bar2"
          style={{ width: `${props.percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
